import React from "react";


import { LuEyeOff } from "react-icons/lu";

import userIon from '../assets/images/icons/user.svg'
import imgBoxIcon from '../assets/images/icons/img-box.svg'
import personsIcon from '../assets/images/icons/persons.svg'
import moneyIcon from '../assets/images/icons/money-icon.svg'
import bcIcon from '../assets/images/icons/bc-icon.svg'
import CreateAccountStep from "../components/CreateAccountStep";

export default function SignUp() {
    return (
        <div className="sign-up-page">
            <div className="inner-main-page">
                {/* <LogoTopBar /> */}
                <div className="inner-page-content">
                    {/* <div className="stepper-titles-section">
                        <div className="stepper-cards-wrapper">
                            <div className="stepper-card active">
                                <div className="icon-wrapper">
                                    <img src={userIon} alt="userIon" />
                                </div>
                                <div className="content-wrapper">
                                    <strong className="medium ">Create Your Account</strong>
                                    <p>You're almost ready to start collaborating with top brands! Let’s begin by setting up your account.</p>
                                </div>
                            </div>
                            <div className="stepper-card">
                                <div className="icon-wrapper">
                                    <img src={imgBoxIcon} alt="imgBoxIcon" />
                                </div>
                                <div className="content-wrapper">
                                    <strong className="medium ">Showcase Your Creativity</strong>
                                    <p>Showcase your talents to stand out and attract brands with a strong profile.</p>
                                </div>
                            </div>
                            <div className="stepper-card">
                                <div className="icon-wrapper">
                                    <img src={personsIcon} alt="personsIcon" />
                                </div>
                                <div className="content-wrapper">
                                    <strong className="medium ">Let’s Talk About Your Audience</strong>
                                    <p>Share your social media presence to help us connect you with the best opportunities.</p>
                                </div>
                            </div>
                            <div className="stepper-card">
                                <div className="icon-wrapper">
                                    <img src={moneyIcon} alt="moneyIcon" />
                                </div>
                                <div className="content-wrapper">
                                    <strong className="medium ">Get Paid for Your Creativity</strong>
                                    <p>Enter the amount you charge for videos and live sessions so they know what to expect when working with you.</p>
                                </div>
                            </div>
                            <div className="stepper-card">
                                <div className="icon-wrapper">
                                    <img src={bcIcon} alt="bcIcon" />
                                </div>
                                <div className="content-wrapper">
                                    <strong className="medium ">Welcome to the Community!</strong>
                                    <p>You're all set! Explore opportunities and take your creator career to new heights. We're excited to have you!</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="onboarding-sign-up-section">
                        <CreateAccountStep/>
                    </div>
                </div>
            </div>
        </div>
    )
}