import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import * as Ably from "ably";
import { AblyProvider, ChannelProvider } from "ably/react";
import DashboardHeader from "../components/DashboardeHeader";
import { useSelector } from "react-redux";

export default function Dashboard() {
  const auth = useSelector((s) => s.auth);
  const client = useMemo(
    () =>
      auth?.user?.id || auth?.user?._id
        ? new Ably.Realtime({
            key: process.env.REACT_APP_ABLY_API_KEY,
            clientId: auth?.user?.id || auth?.user?._id,
          })
        : null,
    [auth?.user?._id, auth?.user?.id]
  );
  return (
    <div className="dashboard-page">
      <AblyProvider client={client}>
        <ChannelProvider channelName="notifications">
          <DashboardHeader />
          <Outlet />
        </ChannelProvider>
      </AblyProvider>
    </div>
  );
}
