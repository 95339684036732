import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'react-circular-progressbar/dist/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import ChoosePlatform from './pages/ChoosePlatform';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import FindJobsPage from './pages/FindJobsPage';
import DashboardHome from './pages/DashboardHome';
import DashboardHomeNoData from './pages/DashboardHomeNoData';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateAsyncThunk } from './redux/pagesSlices/authSlice';
import Authenticated from './layouts/authenticated';
import UnAuthenticated from './layouts/unAuthenticated';
import MyJobsPage from "./pages/MyJobsPage";
import MyProposals from "./pages/MyProposals";
import TrackProposal from "./pages/TrackProposal";
import ProposalDetailPage from "./pages/ProposalDetailPage";
import JobDetailPage from "./pages/JobDetailPage";
import TrackProjectPage from "./pages/TrackProjectPage";
import MessagesPage from "./pages/MessagesPage";
import LoaderComponent from "./components/LoaderComponent";
import MyProfile from "./pages/MyProfile";
import Settings from "./pages/Settings";

function App() {
  const d = useDispatch();
  const auth = useSelector(s => s.auth);
  const loading = auth?.loadings?.authenticateAsyncThunk;
  // const user = auth?.user;


  useEffect(() => {
    d(authenticateAsyncThunk());
  }, [d]);

  if (loading) return <LoaderComponent />;
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Authenticated Component={ChoosePlatform} />} />
          <Route path="/sign-up" element={<UnAuthenticated Component={SignUp} />} />
          <Route path="/sign-in" element={<UnAuthenticated Component={SignIn} />} />
          <Route path="/dashboard" element={<Authenticated Component={Dashboard} />} >
            <Route index path="" element={<Authenticated Component={DashboardHomeNoData} />} />
            <Route path="home" element={<Authenticated Component={DashboardHome} />} />
            <Route path="find-jobs" element={<Authenticated Component={FindJobsPage} />} />
            <Route path="my-jobs" element={<Authenticated Component={MyJobsPage} />} />
            <Route path="my-proposals" element={<Authenticated Component={MyProposals} />} />
            <Route path="proposals/:id" element={<Authenticated Component={ProposalDetailPage} />} />
            <Route path="proposals/:id/track" element={<Authenticated Component={TrackProposal} />} />
            <Route path="job/:id" element={<Authenticated Component={JobDetailPage} />} />
            <Route path="messages" element={<Authenticated Component={MessagesPage} />} />
            <Route path="track-project" element={<Authenticated Component={TrackProjectPage} />} />
            <Route path="my-profile" element={<Authenticated Component={MyProfile} />} />
            <Route path="settings" element={<Authenticated Component={Settings} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
