import React, { useEffect, useState } from "react";

import FindJobsPageHeader from "../components/FindJobsPageHeader";
import JobCardComponent from "../components/JobCardComponent";
import JobCardComponentListView from "../components/JobCardComponentListView";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import { getAvailableJobsAsyncThunk } from "../redux/pagesSlices/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import SideBarComponent from "../components/SideBarComponent";

export default function FindJobsPage() {
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.job.availableJobs);
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    promotionType: [],
    preferredGender: [],
  });
  const [viewMode, setViewMode] = useState("grid");
  const [search, setSearch] = useState("");
  const [budgetType, setBudgetType] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [category, setCategory] = useState("");
  const [promotionType, setPromotionType] = useState("");
  const [sideBarOpen, setSideBarOpen] = useState(true);

  // Search and filter handlers
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleCategoryChange = (e) => setCategory(e.target.value);
  const handlePromotionTypeChange = (e) => setPromotionType(e.target.value);


  const sideBarOpenHandler = () => {
    setSideBarOpen(!sideBarOpen)
  }

  // Fetch jobs based on filter changes
  useEffect(() => {
    const fetchParams = {
      sortBy,
      ...(search && { jobTitle: search }),
      ...(category && { category }),
      ...(budgetType && { budgetType }),
      // ...(promotionType && { promotionType }),
      ...(selectedFilters.promotionType?.length > 0 && { types: selectedFilters.promotionType }),
      ...(selectedFilters.preferredGender?.length > 0 && { gender: selectedFilters.preferredGender.map(item => item.toLowerCase()).filter(item => item !== "all") }),
      ...(selectedFilters.categories?.length > 0 && { categories: selectedFilters.categories.filter(item => item !== "All") }),
      populate: "brandId",
    };

    const timeout = setTimeout(() => {
      dispatch(getAvailableJobsAsyncThunk(fetchParams));
    }, 500);

    return () => clearTimeout(timeout);
  }, [dispatch, sortBy, search, category, budgetType, selectedFilters]);
  console.log("selectedFilters", selectedFilters);
  return (
    <div className="main-inner-page-wrapper">
      <SideBarComponent
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        sideBarOpen={sideBarOpen} sideBarOpenHandler={sideBarOpenHandler} />
      <div className={`inner-page-wrapper ${sideBarOpen ? 'active' : ''}`}>
        <div className="find-jobs-page">
          <FindJobsPageHeader
            sortBy={sortBy}
            setSortBy={setSortBy}
            search={search}
            handleSearchChange={handleSearchChange}
            jobsViewHandler={setViewMode}
            jobsView={viewMode}
          />

          <div className="jobs-section">
            <div className="container">
              <div className="row">
                <ProviderErrorLoadingPagination
                  reducer="job"
                  action="getAvailableJobsAsyncThunk"
                  asyncThunk={getAvailableJobsAsyncThunk}
                  dataKey="availableJobs"
                  Component={
                    viewMode === "grid"
                      ? JobCardComponent
                      : JobCardComponentListView
                  }
                  emptyMessage="No jobs found!"
                  pagination
                  itemKey="job"
                  componentProps={{
                    jobTitle: "Job Title",
                    brandName: "Brand Name",
                    jobPostedTime: "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}
