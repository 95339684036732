import React from "react";
import Modal from "react-bootstrap/Modal";
import pdfIcon from "../assets/images/icons/pdf-icon.svg";
import emojiIcon from "../assets/images/icons/emoji-icon.svg";
import { FaFile } from "react-icons/fa6";

export default function SendFileModal({
  sendFileModal,
  sendFileModalCloseHandler,
  file,
  handleSend,
  messageText,
  setMessageText,
  loading,
}) {
    console.log("🚀 ~ file:", file)
    function formatFileSize(sizeInBytes) {
        if (sizeInBytes >= 1e9) {
          return (sizeInBytes / 1e9).toFixed(2) + " GB";
        } else if (sizeInBytes >= 1e6) {
          return (sizeInBytes / 1e6).toFixed(2) + " MB";
        } else if (sizeInBytes >= 1e3) {
          return (sizeInBytes / 1e3).toFixed(2) + " KB";
        } else {
          return sizeInBytes + " B";
        }
      }
      
  return (
    <div className="send-file-modal-wrapper">
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={sendFileModal}
        className="send-file-modal custom-modal"
        onHide={sendFileModalCloseHandler}
      >
        <Modal.Header sendPhotoModalCloseHandler closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Send File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="file-main-wrapper">
            <div className="img-wrapper">
              <img src={pdfIcon} alt="pdfIcon" />
              {/* <FaFile size={25} /> */}
            </div>
            <div className="text-wrapper">
              <strong className="dark medium">{file?.name}</strong>
              <span>{file?.size&&formatFileSize(file?.size)}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="caption-wrapper">
            <div className="text-wrapper">
              <img src={emojiIcon} alt="emojiIcon" className="emoji" />
              <input
                type="text"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                className="form-control"
                placeholder="Add a caption"
              />
            </div>
          </div>
          <button
            disabled={loading}
            className="btn-style auto_width rounded"
            onClick={handleSend}
          >
            {loading ? "Sending..." : "Send"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
