import React from "react";

import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import VideoLivePricingsListsComponent from "./VideoLivePricingsListsComponent";
import pointerIcon from '../assets/images/icons/pointer-icon.svg'

export default function EditProposalComponent ({editModalState, editModalCloseHandler}) {
    return(
        <div className="edit-proposal-modal-wrapper custom-modal-wrapper">
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={editModalState}
                className="edit-proposal-modal custom-modal"
                onHide={editModalCloseHandler}
            >
                <Modal.Header editModalCloseHandler>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-wrappr">
                        <div className="img-wrapper">
                            <div className="img">
                                <img src={pointerIcon} alt="pointerIcon" />
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <h3>Edit Proposal</h3>
                            <p className="medium medium-bold">Please enter a new price or select from your available options.</p>
                        </div>
                    </div>
                    <div className="pricing-main-wrapper">
                        <VideoLivePricingsListsComponent/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="#" className="btn-style transparent light large" onClick={editModalCloseHandler}>Cancel</Link>
                    <Link to="#" className="btn-style large" onClick={editModalCloseHandler}>Update Proposal</Link>
                </Modal.Footer>
            </Modal>
        </div>
    )
}