import React, { useEffect, useState } from "react";

import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";

import fileIcon from '../assets/images/icons/file-icon.svg'
import VideoLivePricingsListsComponent from "./VideoLivePricingsListsComponent";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { setValueIfExist } from "../utils/utils";

export default function SubmitProposalModal({ submitProposalModalState, editModalCloseHandler, handleApply = () => { }, loading = false }) {

    const creator = useSelector(s => s.auth.creator);
    const [videoPrices, setVideoPrices] = useState([])
    const [livePrices, setLivePrices] = useState([])
    const [offeredVideoPrice, setOfferedVideoPrice] = useState([])
    const [offeredLivePrice, setOfferedLivePrice] = useState([])
    const [proposalComment, setProposalComment] = useState("")
    const resetVidePrices = () => {
        setVideoPrices(creator?.videoPrices ?? [])
        setOfferedVideoPrice([])
    }
    const resetLivePrices = () => {
        setLivePrices(creator?.livePrices ?? [])
        setOfferedLivePrice()
    }
    useEffect(() => {
        if (videoPrices.length === 0) {
            setVideoPrices(creator?.videoPrices ?? [])
        }
        if (livePrices.length === 0) {
            setLivePrices(creator?.livePrices ?? [])
        }
    }, [creator, livePrices.length, videoPrices.length])

    const removeIds = (arrayData = []) => {
        return arrayData.map(item => ({ ...item, _id: undefined, id: undefined }))
    }
    const handleSubmit = () => {
        handleApply({
            ...setValueIfExist({
                offeredLivePrice: removeIds(livePrices.filter(live => offeredLivePrice.includes(live._id))),
                offeredVideoPrice: removeIds(videoPrices.filter(video => offeredVideoPrice.includes(video._id))),
                proposalComment,
            })
        })
    }
    return (
        <div className="submit-proposal-modal-wrapper">
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={submitProposalModalState}
                className="submit-proposal-modal custom-modal"
                onHide={editModalCloseHandler}
            >
                <Modal.Header editModalCloseHandler>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-wrappr">
                        <div className="img-wrapper">
                            <div className="img">
                                <img src={fileIcon} alt="fileIcon" />
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <h3>Submit Proposal</h3>
                            <p className="medium medium-bold">Please select the package(s) you are interested in offering. You may choose multiple options. Once you have made your selections, they will be forwarded to the brand. The brand will then have the opportunity to select one option from each list—either video or live.</p>
                        </div>
                    </div>
                    <div className="pricing-main-wrapper">
                        <VideoLivePricingsListsComponent
                            livePrices={livePrices}
                            videoPrices={videoPrices}
                            setVideoPrices={setVideoPrices}
                            setLivePrices={setLivePrices}
                            offeredVideoPrice={offeredVideoPrice}
                            setOfferedVideoPrice={setOfferedVideoPrice}
                            offeredLivePrice={offeredLivePrice}
                            setOfferedLivePrice={setOfferedLivePrice}
                            resetVidePrices={resetVidePrices}
                            resetLivePrices={resetLivePrices}
                        />
                    </div>
                    <div className="why-us-wrapper">
                        <label className="dark">Tell us why you’re suitable for this job</label>
                        <textarea name="why-us" id="why-us" value={proposalComment} onChange={(e) => setProposalComment(e.target.value)} ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} to="#" className="btn-style transparent light large" onClick={editModalCloseHandler}>Cancel</Button>
                    <Button
                        disabled={loading || (offeredLivePrice.length === 0 && offeredVideoPrice.length === 0)}
                        className="btn-style large"
                        onClick={handleSubmit}>
                        {loading ?
                            <div className="spinner-border text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            :
                            'Submit Proposal'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}