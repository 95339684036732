import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../assets/images/icons/logo.svg";
import { FaPlay } from "react-icons/fa";

import jobImg from "../assets/images/job-img-1.svg";
import mapIcon from "../assets/images/icons/map-icon.svg";
import videoIcon from "../assets/images/icons/video-file-wrapper.svg";
import LiveIcon from "../assets/images/icons/live-icon.svg";
import proposalImg from "../assets/images/proposal-img.svg";

import videoMediaImg from "../assets/images/video-media-img.svg";

import { IoLogoFacebook } from "react-icons/io";
import { FaTiktok } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import SubmitProposalModal from "../components/SubmitProposalModal";
import { useDispatch, useSelector } from "react-redux";
import {
  applyJobAsyncThunk,
  getJobAsyncThunk,
  getJobDetailsAsyncThunk,
} from "../redux/pagesSlices/jobSlice";
import { creatorAgreementAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import { returnArray } from "../utils/utils";
import { parseImagePathToURL } from "../utils/globalFunctions";
import moment from "moment";
import LoaderComponent from "../components/LoaderComponent";

export default function JobDetailPage() {
  const d = useDispatch();
  const { id } = useParams();
  const { jobDetails, appliedJob } = useSelector((s) => s.job);
  const videoRefs = useRef([]);
  const [playingIndex, setPlayingIndex] = useState(null); // Track which video is playing
  // const creator = useSelector((s) => s.auth.creator);
  const loadingApplying = useSelector(
    (s) => s.job.loadings?.applyJobAsyncThunk
  );
  const loading = useSelector((s) => s.job.loadings?.getJobDetailsAsyncThunk);

  useEffect(() => {
    if (id) d(getJobDetailsAsyncThunk(id));
  }, [d, id]);

  const [submitProposalModalState, setSubmitProposalModalState] =
    useState(false);
  const editModalOpenHandler = () => {
    setSubmitProposalModalState(true);
  };
  const editModalCloseHandler = () => {
    setSubmitProposalModalState(false);
  };
  const handleAgreeContract = async (data) => {
    if (loadingApplying) return;
    // const response = await handleShowApply('agree_contract');
    const response = true;
    if (response?.result) {
      d(
        creatorAgreementAsyncThunk({
          id: data?.id ?? data?._id,
        })
      );
    }
  };
  const handleApply = async (data) => {
    if (loadingApplying) return;
    d(
      applyJobAsyncThunk({
        id,
        data,
        callBack: (data) => {
          editModalCloseHandler();
          handleAgreeContract(data);
        },
      })
    );
  };
  const application = jobDetails?.application ?? appliedJob ?? {};
  const hasApplied =
    application &&
    typeof application === "object" &&
    Object.keys(application).length > 0;
  const job = jobDetails?.job ?? {};
  const brand = jobDetails?.job?.brandId ?? {};
  const videoArray = job?.videoUrl?.split(",").map((url) => url.trim());

  const handlePlay = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement.paused) {
      videoElement.play();
      setPlayingIndex(index); // Set the index of the playing video
    } else {
      videoElement.pause();
      setPlayingIndex(null); // Set to null when the video is paused
    }
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="inner-page-wrapper active m-0">
      <div className="job-detail-page">
        <div className="job-detail-page-inner">
          <div className="container large">
            <div className="row">
              <div className="col-md-8">
                <div className="job-detail-page-main-content">
                  <div className="job-top-title-wrapper">
                    <div className="title-wrapper">
                      <h2>{job?.jobTitle}</h2>
                      <div className="main-wrapper">
                        <div className="img-wrapper">
                          <img
                            src={
                              brand?.avatar
                                ? parseImagePathToURL(brand?.avatar)
                                : jobImg
                            }
                            alt="jobImg"
                          />
                        </div>
                        <div className="content-wrapper">
                          <div className="text">
                            <strong className="name medium">
                              <Link to="#" className="blue">
                                {brand?.name}
                              </Link>
                            </strong>
                            <div className="location">
                              <img src={mapIcon} alt="mapIcon" />
                              <p classNambrande="medium medium-bold">
                                {" "}
                                {job?.location}
                              </p>
                            </div>
                          </div>
                          <div className="text">
                            <p className="medium medium-bold m-0">
                              <a href={brand?.website}>{brand?.website}</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper">
                      {hasApplied && (
                        <Link
                          to={`/dashboard/proposals/${
                            application.id ?? application._id
                          }`}
                          className="btn-style"
                        >
                          View Proposal
                        </Link>
                      )}
                      {!hasApplied && (
                        <a
                          href="#"
                          className="btn-style"
                          onClick={editModalOpenHandler}
                        >
                          Submit Proposal
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="job-summary-info">
                    <div className="text">
                      <strong>Content type</strong>
                      <ul className="proposal-type-list">
                        {returnArray(job?.types).map((type, index) => (
                          <li key={index}>
                            <img
                              src={type === "video" ? videoIcon : LiveIcon}
                              alt="Icon"
                            />
                            {type}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="text">
                      <strong>Categories</strong>
                      <ul className="categories-list">
                        {returnArray(job?.categories).map((category, index) => (
                          <li key={index}>{category}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="text">
                      <strong>Preferred ages</strong>
                      <p className="light-bg">
                        {returnArray(job?.ageRange).join(", ")}
                      </p>
                    </div>
                    <div className="text">
                      <strong>Preferred gender</strong>
                      <p className="light-bg">{job?.gender}</p>
                    </div>
                  </div>
                  <div className="main-info-wrapper">
                    <strong className="title dark">Media</strong>
                    <ul className="media-list-wrapper">
                      {returnArray(job?.image).map((media, index) => (
                        <li key={index}>
                          <img src={parseImagePathToURL(media)} alt="media" />
                        </li>
                      ))}
                      <li>
                        <img
                          src={Logo}
                          style={{ objectFit: "contain" }}
                          alt="proposalImg"
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="main-info-wrapper">
                    <strong className="title dark">Job description</strong>
                    <p>{job?.brandId?.description}</p>
                  </div>
                  <div className="main-info-wrapper">
                    <strong className="title dark">Pain points</strong>
                    <p>{job?.jobAttributes?.painPoints}</p>
                  </div>
                  <div className="main-info-wrapper">
                    <strong className="title dark">
                      Content Strategy Guidence
                    </strong>
                    <p>{job?.jobAttributes?.contentStrategy}</p>
                  </div>
                  <div className="main-info-wrapper">
                    <strong className="title dark">Job benefits</strong>
                    <p>{job?.jobAttributes?.benefits}</p>
                  </div>
                  <div className="main-info-wrapper">
                    <strong className="title dark">Video examples</strong>
                    <ul className="sample-videos-list">
                      {videoArray?.length > 0 &&
                        videoArray.map((video, index) => (
                          <li key={index}>
                            <video
                              ref={(el) => (videoRefs.current[index] = el)} // Set ref for each video
                              controls
                              width="600"
                              poster={videoMediaImg}
                              onPlay={() => setPlayingIndex(index)} // Set index when video starts playing
                              onPause={() => setPlayingIndex(null)} // Reset index when video is paused
                            >
                              <source src={video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            {playingIndex !== index && (
                              <div
                                className="icon-wrapper"
                                onClick={() => handlePlay(index)}
                              >
                                <FaPlay />
                              </div>
                            )}
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="main-info-wrapper">
                    <div className="title">
                      <strong className="title dark">Socials</strong>
                      <strong className="light">
                        Job posted:{" "}
                        <span>
                          {moment(job?.createdAt).format("MM/DD/YYYY")}
                        </span>
                      </strong>
                    </div>
                    <ul className="social-icons-list">
                      <li>
                        <Link href="#">
                          <FaTiktok />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <IoLogoFacebook />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <FaInstagram />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <FaYoutube />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="similar-jobs-main-wrapper">
                  <strong className="title dark">Similar Jobs</strong>
                  <div className="row">
                    {/* jobs listing here (dont change classses ) */}

                    {/* {jobsArray.map((job, index) => (
                                        <div className="col-md-12 mb-3" key={index}>
                                            <JobCardComponent job={job} jobImg={job.jobImg} jobTitle={job.jobTitle} brandImg={job.brandImg} brandName={job.brandName} jobPostedTime={job.jobPostedTime} />
                                        </div>
                                    ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubmitProposalModal
          handleApply={handleApply}
          loading={loadingApplying}
          submitProposalModalState={submitProposalModalState}
          editModalCloseHandler={editModalCloseHandler}
        />
      </div>
    </div>
  );
}
