import React from "react";
import { AudioRecorder } from "react-audio-voice-recorder";

const AudioRecorderComponent = ({ sendChatMessage }) => {
  const addAudioElement = (blob) => {
    // const url = URL.createObjectURL(blob);
    const file = new File([blob], 'recording.webm', { type: 'audio/webm' });
    sendChatMessage({file})
  };

  return (
    <AudioRecorder
      onRecordingComplete={addAudioElement}
      audioTrackConstraints={{
        noiseSuppression: true,
        echoCancellation: true,
      }}
    />
  );
};

export default AudioRecorderComponent;
