import React, { useEffect, useState } from "react";
import MyJobsPageHeader from "../components/MyJobsPageHeader";
import MyJobsListings from "../components/MyJobsListings";
import NoJobsComponent from "../components/NoJobsComponent";
import { useDispatch, useSelector } from "react-redux";
import { getHiredJobsAsyncThunk } from "../redux/pagesSlices/jobSlice";
import PaginationComponent from "../components/PaginationComponent";

export default function MyJobsPage() {

  const [activeJobsTab, setActiveJobsTabs] = useState('all');
  const activeJobTabsHandler = (tab) => {
    setActiveJobsTabs(tab)
  }
  const dispatch = useDispatch();

  const [hasListings, setHasListings] = useState(true);
  const params = useSelector(s => s.job?.paramsForThunk?.getHiredJobsAsyncThunk);
  const loading = useSelector(s => s.job?.loadings?.getHiredJobsAsyncThunk);
  const [viewMode, setViewMode] = useState('grid')
  const [search, setSearch] = useState('')
  const [budgetType, setBudgetType] = useState('')
  const [sortBy, setSortBy] = useState('createdAt:desc');
  const [category, setCategory] = useState('');
  const [promotionType, setPromotionType] = useState('');
  const [selectedSortValue, setSelectedSortValue] = useState('')

  const handleCategoryChange = (e) => setCategory(e.target.value);
  const handlePromotionTypeChange = (e) => setPromotionType(e.target.value);
  const handleSearchChange = (e) => setSearch(e.target.value);



  const categories = [
    "Beauty & Care",
    "Business & Finance",
    "Fashion & Style",
    "Food & Drinks",
    "Gaming & Tech",
    "Health & Wellness",
    "Home & Garden",
    "Kids & Parenting",
    "Lifestyle",
    "Outdoors & Nature",
    "Pets",
    "Sport & Fitness",
    "Travel",
  ];

  const viewModeHandler = (view) => {
    setViewMode(view)
  }
  const jobs = useSelector(s => s.job.hiredJobs);

  useEffect(() => {
    dispatch(getHiredJobsAsyncThunk({ sortBy, populate: 'brandId' }))
  }
    , [])
  const goToPage = (page) => {
    const reqParams = { ...(params ? params : {}), page, }
    dispatch(getHiredJobsAsyncThunk(reqParams))
  }
  const handlePerPageChange = (limit) => {
    const reqParams = { ...(params ?? {}), limit };
    dispatch(getHiredJobsAsyncThunk(reqParams));
  };
  useEffect(() => {
    // wait user stop typing
    const timeout = setTimeout(() => {
      const reqParams = {
        ...(params ? params : {}), ...(search ? { jobTitle: search } : {}),
        sortBy,
        populate: 'brandId',
        ...(budgetType ? { budgetType } : {}),
        ...(promotionType ? { types: promotionType } : {}),
        ...(category ? { categories: category } : {}),
      }
      dispatch(getHiredJobsAsyncThunk(reqParams))
    }, 500)
    return () => clearTimeout(timeout)
  }, [search, sortBy, budgetType, category, promotionType])
  const [itemsView, setItemsView] = useState('grid')
  const pageViewHandler = (view) => {
    setItemsView(view)
  }
  
  return (
    <div className="inner-page-wrapper active m-0">
      <div className="my-jobs-page">
        <MyJobsPageHeader title={"My Jobs"} containerFluid={true} />
        {hasListings ?
          <>
            <div className="inner-page-section">
              <div className="top-bar-wrapper">
                <ul className="tabs-wrapper">
                  <li><button className={`custom-btn ${activeJobsTab == 'all' ? 'active' : ''}`} onClick={() => activeJobTabsHandler('all')}>All <span className="num">2</span>  </button> </li>
                  <li><button className={`custom-btn ${activeJobsTab == 'in_progress' ? 'active' : ''}`} onClick={() => activeJobTabsHandler('in_progress')}>In Progress <span className="num">2</span></button></li>
                  <li><button className={`custom-btn ${activeJobsTab == 'complete' ? 'active' : ''}`} onClick={() => activeJobTabsHandler('complete')}>Completed <span className="num">0</span></button></li>
                </ul>
              </div>
              <MyJobsListings tab={activeJobsTab} />
            </div>
          </>
          :
          // if there is no listing than this component will render here

          <NoJobsComponent />
        }
        <PaginationComponent
          onItemsPerPageChange={handlePerPageChange}
          onPageChange={goToPage}
          initialItemsPerPage={jobs?.limit}
          initialPage={1}
          itemsPerPageOptions={[10, 20, 50]}
          totalItems={jobs?.totalResults}
        />
      </div>
    </div>
  )
}