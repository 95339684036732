import React from "react";

import noJobsImg from '../assets/images/icons/no-jobs-img.svg'
import { Link } from "react-router-dom";

export default function NoJobsComponent() {
    return (
        <div className="no-jobs-main-wrapper">
            <div className="main-wrapper">
                <div className="img-wrapper">
                    <img src={noJobsImg} alt="noJobsImg" className="no-jobs-img" />
                </div>
                <div className="content-wrapper">
                    <strong className="dark large">No Jobs Yet? Let’s Get Started!</strong>
                    <p>It looks like you haven’t applied for any jobs yet. Explore new opportunities and <br /> start your content creation journey with BMC.</p>
                    <div className="btn-wrapper">
                        <Link to="#" className="btn-style ">Discover Jobs</Link>
                        <Link to="#" className="btn-style transparent light">How it Works</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}