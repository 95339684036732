import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
const toast = { error: () => { }, success: () => { } };
// Start Creator Slices
///////////////////////////////////////////////////

export const getCreatorsAsyncThunk = createAsyncThunk(
  "creator/getCreatorsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getCreators(params);
    return response?.data;
  })
);

export const getCreatorProgressAsyncThunk = createAsyncThunk(
  "creator/getCreatorProgressAsyncThunk",
  catchAsync(async (data, _) => {
    var response = await ApiRequests.getCreatorPerformance(data);
    response = await response.json();
    return response;
  })
);

export const getCreatorAsyncThunk = createAsyncThunk(
  "creator/getCreatorAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getCreator(id);
    return response?.data;
  })
);

export const getCreatorDetailAsyncThunk = createAsyncThunk(
  "creator/getCreatorDetailAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getCreatorDetails(id);
    return response?.data;
  })
);

export const createCreatorAsyncThunk = createAsyncThunk(
  "creator/createCreatorAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    // const state = getState();
    const response = await ApiRequests.createCreator(data);
    if (response.status == 204) {
      toast.success("Creator Create Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getCreatorsAsyncThunk({ ...params, populate: "creator_id", role: "Creator" })
    );
    // dispatch(getCreatorsByIdsAsyncThunk({ ...state.creators?.paramsForThunk?.getCreatorsByIdsAsyncThunk}))
    return response?.data;
  })
);

export const updateCreatorAsyncThunk = createAsyncThunk(
  "creator/updateCreatorAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.updateCreator({ id, data });
    if (response.status == 204) {
      toast.success("Creator Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getCreatorsAsyncThunk({ ...params, populate: "creator_id", role: "Creator" })
    );
    // dispatch(getCreatorsByIdsAsyncThunk({ populate: "image,creator_id", ...state.creators?.paramsForThunk?.getCreatorsByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);

export const reviewCreatorAsyncThunk = createAsyncThunk(
  "creator/reviewCreatorAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.reviewCreator({ id, data });
    if (response.status == 204) {
      toast.success("Creator Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);


export const reloadCreatorStatsAsyncThunk = createAsyncThunk(
  "creator/reloadCreatorStatsAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.reloadCreatorStats({ id, data });
    if (response.status == 204) {
      toast.success("Creator Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteCreatorAsyncThunk = createAsyncThunk(
  "creator/deleteCreatorAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteCreator(id);
    if (response.status == 204) {
      toast.success("Creator Deleted Successfully!");
      let params = {};
      let state = getState().listings;
      if (state.search) params.name = state.search;
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(
        getCreatorsAsyncThunk({ ...params, populate: "creator_id", role: "Creator" })
      );
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  creators: {
    page: 1,
    creators: [],
    totalPages: 1,
  },
  creatorPerformances: {
    "status": "success",
    "time_interval": {
      "start": "2024-10-10T00:00:00-08:00",
      "end": "2024-10-11T00:00:00-08:00",
      "granularity": "all"
    },
    "dimensions": [
      5,
      4,
      2
    ],
    "pagination": {
      "current_page": 0,
      "total_pages": 0,
      "page_size": 20,
      "total_count": 11
    },
    "columns": [],
    "rows": []
  },
  creatorsCount: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  inviteCreator: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  creatorExport: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  creatorRole: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  creatorsList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  creator: null,
  creatorDetails: null,
  reloadStats: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const creatorSlice = createSlice({
  name: "creators",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getCreatorsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.creators = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getCreatorsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.creators = {
            ...action.payload,
            creators: state?.creators?.results.concat(action?.payload?.results),
          };
        } else {
          state.creators = action.payload;
        }
      })
      .addCase(getCreatorProgressAsyncThunk.fulfilled, (state, action) => {
        state.creatorPerformances = action.payload;
      })
      .addCase(getCreatorAsyncThunk.fulfilled, (state, action) => {
        state.creator = action.payload
      })
      .addCase(getCreatorDetailAsyncThunk.pending, (state, action) => {
        state.creatorDetails = {}
      })
      .addCase(getCreatorDetailAsyncThunk.fulfilled, (state, action) => {
        state.creatorDetails = action.payload
      })
      .addCase(reloadCreatorStatsAsyncThunk.fulfilled, (state, action) => {
        state.reloadStats = action.payload
      })
      .addCase(deleteCreatorAsyncThunk.fulfilled, (state, action) => {
        state.creators = {
          ...state.creators,
          totalResults: state.creators?.totalResults - 1,
          results: state.creators?.results.filter((e) => e.id != action.payload),
        };
        state.creatorsCount = {
          ...state.creatorsCount,
          totalResults: state.creatorsCount?.totalResults - 1,
          results: state.creatorsCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getCreatorsAsyncThunk,
            getCreatorAsyncThunk,
            deleteCreatorAsyncThunk,
            createCreatorAsyncThunk,
            reviewCreatorAsyncThunk,
            reloadCreatorStatsAsyncThunk,
            updateCreatorAsyncThunk,
            getCreatorDetailAsyncThunk,
            getCreatorProgressAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default creatorSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  creatorSlice.actions;
