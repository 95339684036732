import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function JobPostPickContent({ setCreatorInfo, creatorInfo = {}, setCanMove }) {
    const contentArray = [
        "⚡ Electronics",
        "👗 Fashion",
        "🍎 Beauty & Health",
        "🏠 Home & Living",
        "🎮 Toys & Games",
        "🚗 Automotive",
        "📷 Books & Media",
        "🛒 Groceries & Food",
        "🐶 Pet Supplies",
        "💼 Office Supplies",
        "🛠️ Tools & Hardware",
        "💎 Jewellery & Watches",
        "🏃🏻 Sports & Outdoors",
        "👶🏻 Baby & Kids",
        "🎨 Arts & Collectibles",
    ];

    // Define validation schema with Yup
    const validationSchema = Yup.object({
        categories: Yup.array()
            .min(1, "Please select at least one category")
            .required("You must choose at least one category"),
    });

    // Initialize Formik
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isValid,
    } = useFormik({
        initialValues: {
            categories: creatorInfo.categories || [],
        },
        validationSchema,
        validateOnMount: true, 
        onSubmit: (values) => {
            // Submit handler
            if (setCreatorInfo && typeof setCreatorInfo === "function") {
                setCreatorInfo(values);
            }
        },
    });

    // Update move state based on form validity
    useEffect(() => {
        setCanMove((prev) => ({ ...prev, 3: isValid }));
    }, [isValid, setCanMove]);

    useEffect(() => {
        setCreatorInfo(values);
    }, [values]);

    const handleCategoryChange = (category) => {
        const currentCategories = values.categories;
        if (currentCategories.includes(category)) {
            // Remove category if it's already selected
            const updatedCategories = currentCategories.filter((item) => item !== category);
            handleChange({ target: { name: "categories", value: updatedCategories } });
        } else {
            // Add category if it's not selected
            const updatedCategories = [...currentCategories, category];
            handleChange({ target: { name: "categories", value: updatedCategories } });
        }
    };

    return (
        <div className="job-post-inner-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="modal-text-wrapper">
                    <h2>Pick Your Content Style</h2>
                    <p className="medium medium-bold">
                        Choose the categories that fit your content best. This helps brands <br />
                        find creators like you who vibe with their audience.
                    </p>
                </div>
                <div className="pick-content-list-wrapper">
                    <ul className="pick-content-list">
                        {contentArray.map((category, index) => (
                            <li key={index}>
                                <label className="custom-radio">
                                    <span>{category}</span>
                                    <input
                                        type="checkbox"
                                        name="categories"
                                        value={category}
                                        checked={values.categories.includes(category)}
                                        onChange={() => handleCategoryChange(category)}
                                        onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                        ))}
                    </ul>
                    {touched.categories && errors.categories && (
                        <div className="invalid-feedback">{errors.categories}</div>
                    )}
                </div>
            </form>
        </div>
    );
}
