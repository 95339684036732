import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import { BiErrorCircle } from "react-icons/bi";
import PaginationComponent from "../components/PaginationComponent";
import PaginateLoaderComponent from "../components/PaginateLoaderComponent";

function ProviderErrorLoadingPagination({
  reducer,
  action,
  Parent = ({ children }) => <>{children}</>, // Default fallback for Parent
  dataKey,
  Component,
  asyncThunk,
  emptyMessage = "No Data Found!",
  componentProps,
  pagination,
  itemKey = "item",
}) {
  const dispatch = useDispatch();

  // Memoize selectors to prevent re-renders
  const { loading, response, error, serverParams, errorMessages } = useSelector((state) => ({
    loading: state[reducer]?.loadings[action],
    response: state[reducer]?.[dataKey],
    error: state[reducer]?.errors[action],
    serverParams: state[reducer]?.paramsForThunk[action],
    errorMessages: state[reducer]?.errorMessages[action],
  }));

  // Handle page change for pagination
  const goToPage = (page) => {
    const reqParams = { ...(serverParams ?? {}), page };
    dispatch(asyncThunk(reqParams));
  };
  const handlePerPageChange = (limit) => {
    const reqParams = { ...(serverParams ?? {}), limit };
    dispatch(asyncThunk(reqParams));
  };
  const renderError = () => (
    <div className="data-error">
      <Row>
        <Col lg={6}>
          <div className="img-box">
            <BiErrorCircle size={100} color="#d3d3d3" />
          </div>
        </Col>
        <Col lg={6}>
          <div className="text-box">
            <h1>{errorMessages || "Something went wrong"}</h1>
            <p>Something went wrong, click to reload.</p>
            <button
              onClick={() => dispatch(asyncThunk(serverParams || {}))}
              className="btn btn-primary"
            >
              Reload
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );

  if (error) return renderError();

  if (!response?.results?.length && !loading) {
    return (
      <Container className="text-center">
        {emptyMessage}
      </Container>
    )
  }

  return (
    <>
      <Parent>
        {response?.results?.map((item, index) => (
          <Component {...componentProps} key={index} {...{ [itemKey]: item }} />
        ))}
      </Parent>
      {loading && <PaginateLoaderComponent />}
      {pagination && (
        <PaginationComponent
          onItemsPerPageChange={handlePerPageChange}
          onPageChange={goToPage}
          initialItemsPerPage={response?.limit}
          initialPage={response?.page}
          totalItems={response?.totalResults}
        />
      )}
    </>
  );
}

export default React.memo(ProviderErrorLoadingPagination);
