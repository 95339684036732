import React, { useEffect, useState } from "react";

import { motion, AnimatePresence } from 'framer-motion';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Modal from 'react-bootstrap/Modal';

import logo from '../assets/images/icons/logo.svg'
import { FiArrowRight } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import { HiMiniInformationCircle } from "react-icons/hi2";

import JobPostStayInLoop from "./JobPostStayInLoop";
import JobPostConnectTikTokShop from "./JobPostConnectTikTokShop";
import JobPostWhatMakesYou from "./JobPostWhatMakesYou";
import JobPostPickContent from "./JobPostPickContent";
import JobPostUGCRate from "./JobPostUGCRate";
import JobPostShopRates from "./JobPostShopRates";
import JobPostWelcomeToBMC from "./JobPostWelcomeToBMC";
import JobPostShopRatesSamplePreview from "./JobPostShopRatesSamplePreview";
import { useDispatch } from "react-redux";
import { setScreenLock, updateCreatorProfileAsyncThunk } from "../redux/pagesSlices/authSlice";
import { setValueIfExist } from "../utils/utils";

export default function JobPostModalComponent(props, { modalCloseHandler }) {

    const [activeStep, setActiveStep] = useState(0);
    const [canMove, setCanMove] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
    });
    const [creatorInfo, setCreatorInfo] = useState({
        name: "",
        categories: "",
        age: "",
        tikTokUserName: "",
        email: "",
        phone: "",
        bio: "",
        avatar: "",
        gender: "",
        ugc_price: "",
        portfolio: "",
        status: "",
        ip_price: "",
        steps: "",
        dob: "",
        location: "United States",
        sample_videos: [],
        // socials: [
        //     {
        //         type: "TikTokShop",
        //         url: "",
        //         f_count: 0,
        //     },
        // ],
        videoPrices: [
            {
                duration: "monthly",
                durationCount: 1,
                count: 5,
                price: 0
            },
            {
                duration: "monthly",
                durationCount: 1,
                count: 10,
                price: 0
            },
            {
                duration: "monthly",
                durationCount: 1,
                count: 20,
                price: 0
            },
            {
                duration: "monthly",
                durationCount: 1,
                count: 30,
                price: 0
            },
        ],
        livePrices: [
            {
                duration: "weekly",
                durationCount: 1,
                count: 4,
                price: 0
            }, {
                duration: "weekly",
                durationCount: 1,
                count: 12,
                price: 0
            }, {
                duration: "weekly",
                durationCount: 1,
                count: 20,
                price: 0
            }, {
                duration: "weekly",
                durationCount: 1,
                count: 30,
                price: 0
            },
        ],


    });

    const handleNext = () => {
        if (canMove[activeStep]) {
            if (activeStep < 5) setActiveStep(activeStep + 1); // Adjust based on the number of steps
            if (activeStep === 5) handleSubmit();
        }

    };

    const handleBack = () => {
        if (activeStep > 0) setActiveStep(activeStep - 1);
    };
    const updateCreatorInfo = (newCreator) => {
        // Check if the newCreator is different from the current creatorInfo
        setCreatorInfo(prev => {
            // If newCreator is the same as prev, do nothing
            if (JSON.stringify(prev) === JSON.stringify(newCreator)) {
                return prev; // No update needed
            }
            return { ...prev, ...newCreator }; // Update if different
        });
    };

    const d = useDispatch();
    const handleSubmit = () => {
        d(setScreenLock(true));
        d(updateCreatorProfileAsyncThunk({ data: setValueIfExist(creatorInfo), callBack: () => setActiveStep(6) }));
    }
    return (
        <div className="job-post-modal-component">
            <Modal
                {...props}
                size="lg"
                className="job-post-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {activeStep !== 7 &&
                            <div className="logo-wrapper">
                                <img src={logo} alt="logo" />
                            </div>
                        }
                        {activeStep === 7 &&
                            <button className="custom-btn blue medium-bold back-btn" onClick={() => setActiveStep(5)} ><FiArrowLeft /> Back</button>
                        }
                        {activeStep === 5 &&
                            <>
                                <div className="dont-worry-about-access-div">
                                    <HiMiniInformationCircle />
                                    <span className="medium">Don’t worry you’ll still be able to access this in your profile settings</span>
                                </div>
                            </>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="job-post-steps-wrapper">
                        <form action="#" className="form">
                            <motion.div layout className="step-content">
                                <AnimatePresence mode="wait">
                                    <motion.div
                                        key={activeStep}
                                        initial={{ opacity: 0, x: 100 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: -100 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        {activeStep === 0 && <>
                                            <JobPostConnectTikTokShop
                                                creatorInfo={creatorInfo}
                                                setCreatorInfo={updateCreatorInfo}
                                                setCanMove={setCanMove} />
                                        </>
                                        }
                                        {activeStep === 1 && <>
                                            <JobPostStayInLoop
                                                creatorInfo={creatorInfo}
                                                setCreatorInfo={updateCreatorInfo}
                                                setCanMove={setCanMove} />
                                        </>
                                        }
                                        {activeStep === 2 && <>
                                            <JobPostWhatMakesYou creatorInfo={creatorInfo}
                                                setCreatorInfo={updateCreatorInfo}
                                                setCanMove={setCanMove} />
                                        </>
                                        }
                                        {activeStep === 3 && <>
                                            <JobPostPickContent
                                                creatorInfo={creatorInfo}
                                                setCreatorInfo={updateCreatorInfo}
                                                setCanMove={setCanMove}
                                            />
                                        </>
                                        }
                                        {activeStep === 4 && <>
                                            <JobPostUGCRate
                                                creatorInfo={creatorInfo}
                                                setCreatorInfo={updateCreatorInfo}
                                                setCanMove={setCanMove}
                                            />
                                        </>
                                        }
                                        {activeStep === 5 && <>
                                            <JobPostShopRates
                                                creatorInfo={creatorInfo}
                                                setCreatorInfo={updateCreatorInfo}
                                                setCanMove={setCanMove}
                                                setActiveStep={setActiveStep} />
                                        </>
                                        }
                                        {activeStep === 6 && <>
                                            <JobPostWelcomeToBMC />
                                        </>
                                        }
                                        {activeStep === 7 && <>
                                            <JobPostShopRatesSamplePreview />
                                        </>
                                        }
                                    </motion.div>
                                </AnimatePresence>
                            </motion.div>
                        </form>
                    </div>
                </Modal.Body>
                {(activeStep !== 6 && activeStep !== 7) &&
                    <>
                        <Modal.Footer>
                            <ProgressBar now={((activeStep + 1) / 7) * 100} />
                            <button className="btn-style" onClick={handleBack} disabled={activeStep === 0} ><FiArrowLeft /> Previous</button>
                            <button className="btn-style" onClick={handleNext} disabled={activeStep === 6} >Next <FiArrowRight /> </button>
                        </Modal.Footer>
                    </>
                }
            </Modal>
        </div>
    )
}