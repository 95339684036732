import React, { useEffect, useState } from "react";
import MyJobsPageHeader from "../components/MyJobsPageHeader";
import NoJobsComponent from "../components/NoJobsComponent";
import MyProposalsListing from "../components/MyProposalsListing";
import { useDispatch, useSelector } from "react-redux";
import {
    getApplicationAsyncThunk,
    getApplicationsAsyncThunk,
    getMyApplicationsAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import { RenderMap, returnArray } from "../utils/utils";
import PaginationComponent from "../components/PaginationComponent";
import SideBarComponent from "../components/SideBarComponent";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";

export default function MyProposals() {
    const dispatch = useDispatch();
    const params = useSelector(
        (s) => s.application?.paramsForThunk?.getMyApplicationsAsyncThunk
    );
    const [myJobsViewModal, setMyJobsViewModal] = useState(false);
    const [activeTabs, setActiveTabs] = useState("all");
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState("createdAt:desc");
    const [sideBarOpen, setSideBarOpen] = useState(true);

    const handleClose = () => setMyJobsViewModal(false);
    const handleShow = () => setMyJobsViewModal(true);

    const activeTabHandler = (tab) => {
        setActiveTabs(tab);
    };

    const sideBarOpenHandler = () => {
        setSideBarOpen(!sideBarOpen);
    };

    const goToPage = (page) => {
        const reqParams = { ...(params ? params : {}), page, sortBy };
        dispatch(getMyApplicationsAsyncThunk(reqParams));
    };
    const applications = useSelector((s) => s.application.myApplications);
    console.log("applications", applications);
    useEffect(() => {
        // pending, approved, rejected
        dispatch(getMyApplicationsAsyncThunk({ populate: "jobId", sortBy }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, sortBy]);
    const handleSearchChange = (e) => setSearch(e.target.value);
  
    return (
        <div className="main-inner-page-wrapper">
            <SideBarComponent
                sideBarOpen={sideBarOpen}
                sideBarOpenHandler={sideBarOpenHandler}
            />
            <div className={`inner-page-wrapper ${sideBarOpen ? "active" : ""}`}>
                <div className="my-proposals-page">
                    <MyJobsPageHeader
                        title="My Proposals"
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        search={search}
                        handleSearchChange={handleSearchChange}
                        containerFluid={true}
                    //  jobsViewHandler={jobsViewHandler}
                    //  jobsView={jobsView}
                    />

                    <div className="my-proposals-listings">
                        <div className="table-wrapper">

                            <ProviderErrorLoadingPagination
                                reducer="application"
                                action="getMyApplicationsAsyncThunk"
                                asyncThunk={getMyApplicationsAsyncThunk}
                                dataKey="myApplications"
                                Component={MyProposalsListing}
                                emptyMessage="No applications found!"
                                pagination
                                Parent={({ children }) => <table>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "start", paddingLeft: 133 }}>
                                                <strong className="white small">Job Name</strong>
                                            </th>
                                            <th style={{ textAlign: "start" }}>
                                                <strong className="white small">Brand Name</strong>
                                            </th>
                                            <th style={{ textAlign: "start" }}>
                                                <strong className="white small">Proposal Type</strong>
                                            </th>
                                            <th style={{ textAlign: "start" }}>
                                                <strong className="white small">Status</strong>
                                            </th>
                                            <th style={{ textAlign: "start" }}>
                                                <strong className="white small">Applied Date</strong>
                                            </th>
                                            <th style={{ textAlign: "end" }}>
                                                <strong className="white small">Action</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{children} </tbody>
                                </table>}
                                itemKey="application"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
