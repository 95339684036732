import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import envelopIcon from '../assets/images/icons/envelop-icon.svg'
import notificationIcon from '../assets/images/icons/notification-icon.svg'
import avatarIcon from '../assets/images/icons/avatar-icon.svg'
import Logo from '../assets/images/icons/logo.svg'
import userLightIcon from '../assets/images/icons/user-light-icon.svg'
import settingsLightIcon from '../assets/images/icons/settings-light-icon.svg'
import helpLightIcon from '../assets/images/icons/help-light-icon.svg'
import logoutIcon from '../assets/images/icons/logout-light-icon.svg'
import settingIcon from '../assets/images/icons/settings-light-icon.svg'
import xIcon from '../assets/images/icons/x-icon.svg'

import { useDispatch, useSelector } from "react-redux";
import { TbLogout } from "react-icons/tb";
import { userLogoutAsyncThunk } from "../redux/pagesSlices/authSlice";
import { Button } from "react-bootstrap";
import NotificationComponent from "./NotificationsComponent";

export default function DashboardHeader() {
    const user = useSelector(s => s.auth.user);
    const d = useDispatch();
    const handleLogout = () => {
        d(userLogoutAsyncThunk());
    }
    const [hasNotification, setHasNotification] = useState(true);
    const [showNotification, setShowNotifications] = useState(false);
    const notificationRef = useRef(null);

    const showNotificationsHandler = () => {
        setShowNotifications(true)
    }
    const hideNotificationsHandler = () => {
        setShowNotifications(false)
    }   

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            setShowNotifications(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    return (
        <header className="dashboard-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 align-self-center">
                        <div className="logo-wrapper">
                            <a href="#">
                                <img src={Logo} alt="Logo" className="logo" />
                            </a>
                        </div>
                        <div className="header-menu">
                            <ul className="menu">
                                <li><NavLink exact to="/dashboard/home" activeClassName="active">Dashboard</NavLink></li>
                                <li><NavLink to="/dashboard/find-jobs" activeClassName="active">Find Jobs</NavLink></li>
                                <li><NavLink to="/dashboard/my-proposals" activeClassName="active">Proposals</NavLink></li>
                                <li><NavLink to="/dashboard/my-jobs" activeClassName="active">My Jobs</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <div className="right-menu">
                            <ul className="menu">   
                                <li className="has-notification"><Link to="/dashboard/messages"><img src={envelopIcon} alt="envelopIcon" /></Link></li>
                                <li className="has-notification"><Link to="#" onClick={showNotificationsHandler}><img src={notificationIcon} alt="notificationIcon" /></Link>
                                  
                                </li>
                            </ul>
                            <div className="avatar-dropdown-wrapper">
                                <img src={avatarIcon} alt="avatarIcon" className="avatar-img" />
                                <DropdownButton
                                    id={`dropdown-button-drop-down`}
                                    drop={'down'}
                                    variant="secondary"
                                    className="custom-dropdown"
                                    title={user.userName}
                                >
                                    <Dropdown.Item eventKey="1" href='/dashboard/my-profile'><img src={userLightIcon} alt="userLightIcon" /> View profile</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" href='/dashboard/settings'><img src={settingsLightIcon} alt="userLightIcon" /> Settings</Dropdown.Item>
                                    <Dropdown.Item eventKey="3"><img src={helpLightIcon} alt="userLightIcon" /> Help & Support</Dropdown.Item>
                                    <Dropdown.Item onClick={handleLogout} eventKey="4"><img src={logoutIcon} alt="userLightIcon" /> Log out</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationComponent hasNotification={hasNotification} showNotification={showNotification} hideNotificationsHandler={hideNotificationsHandler} notificationRef={notificationRef} />
        </header>
    )
}
