import React, { useState } from "react";

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

export default function DashboardTrendsChartComponent({trendActiveStateHandler, trendActiveState}) {

  
 
    return (
        <div className="dashboard-trends-chart-tabs">
            <div className="topbar-wrapper">
                <div className="tabs-wrapper">
                    <ul className="tabs-list">
                        <li><a href="#" className={`medium-bold ${trendActiveState == 'gmv' ? 'active' : ''}`} onClick={() => trendActiveStateHandler('gmv')}>GMV</a></li>
                        <li><a href="#" className={`medium-bold ${trendActiveState == 'itemSold' ? 'active' : ''}`} onClick={() => trendActiveStateHandler('itemSold')}>Items Sold</a></li>
                        <li><a href="#" className={`medium-bold ${trendActiveState == 'videoGpm' ? 'active' : ''}`} onClick={() => trendActiveStateHandler('videoGpm')}>Video GPM</a></li>
                        <li><a href="#" className={`medium-bold ${trendActiveState == 'liveViews' ? 'active' : ''}`} onClick={() => trendActiveStateHandler('liveViews')}>Avg. Live Viewers</a></li>
                        <li><a href="#" className={`medium-bold ${trendActiveState == 'videoViews' ? 'active' : ''}`} onClick={() => trendActiveStateHandler('videoViews')}>Avg. Video Viewers</a></li>
                        <li><a href="#" className={`medium-bold ${trendActiveState == 'liveGpm' ? 'active' : ''}`} onClick={() => trendActiveStateHandler('liveGpm')}>Live GPM</a></li>
                    </ul>
                </div>
                <div className="right-menu">
                    <div className="gmv-text">
                        <span className="color" style={{background:'#578BFC'}}></span>
                        <span className="dark">GMV</span>
                    </div>
                    <div className="duration-dropdown-wrapper">
                        <DropdownButton
                            id={`dropdown-button-drop-down`}
                            drop={'down'}
                            variant="secondary"
                            className="custom-dropdown"
                            title={`This month`}
                        >
                            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                            <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                            <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
            </div>
        </div>
    )
}