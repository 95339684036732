import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoaderComponent from '../components/LoaderComponent';

export default function UnAuthenticated({ Component }) {
  const auth = useSelector(s => s.auth);
  const loading = auth?.loadings?.authenticateAsyncThunk;
  if (loading) return <LoaderComponent />
  if (auth?.user) return <Navigate to="/dashboard" />
  return (
    <Component />
  )
}