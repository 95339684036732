import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Logo from '../assets/images/icons/logo.svg'

import GoogleIcon from '../assets/images/icons/google-icon.svg'
import appleIcon from '../assets/images/icons/apple-icon.svg'
import { LuEyeOff } from "react-icons/lu";


import { FaRegCheckCircle } from "react-icons/fa";
import { userRegisterAsyncThunk } from '../redux/pagesSlices/authSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

const validationSchema = Yup.object({
    firstName: Yup.string()
        .required('First name is required')
        .min(3, 'Name must be at least 3 characters'),
    lastName: Yup.string()
        .required('Last name is required')
        .min(3, 'Name must be at least 3 characters'),
    role: Yup.string().required('Role is required').default('creator'),
    userName: Yup.string().matches(/^[a-zA-Z0-9_]*$/, 'Username must contain only letters, numbers, or underscores').required('Username is required'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Must contain at least one lowercase character')
        .matches(/[A-Z]/, 'Must contain at least one uppercase character')
        .matches(/[0-9]/, 'Must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must contain at least one special character'),
});
export default function CreateAccountStep() {
    const router = useNavigate();
    const d = useDispatch();
    const handleSubmit = async (values, { resetForm }) => {
        console.log(values);
        // const confirmPassword = values.confirmPassword
        // delete values.confirmPassword;
        //   const response = await ApiRequests.register({ ...values, role });
        await d(userRegisterAsyncThunk({ data: values, router }));
        // resetForm();

    };
    return (
        <div className='create-account-step-wrapper'>
            <div className="logo-wrapper text-center">
                <Link to="/">
                    <img src={Logo} alt="Logo" className='logo' />
                </Link>
            </div>
            <div className="text-wrapper">
                <h2>Create Your Creator Account</h2>
                <p>You're just a few steps away from collaborating with top brands! Let’s get started by setting up your account.</p>
            </div>
            <div className="sign-up-choice-wrapper">
                <Link to="#" className="btn-style google-login-btn large full"><img src={GoogleIcon} alt="GoogleIcon" /> Sign up with Google</Link>
            </div>
            <div className="or-divider">
                <strong className='dark small'>OR</strong>
            </div>
            <div className="form-wrapper">
                <Formik
                    initialValues={{ firstName: '', lastName: '', email: '', password: '', userName: '', role: 'creator' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                // onSubmit={(values, { setSubmitting }) => {
                //     console.log('Form submitted:', values);
                //     setSubmitting(false); // Stop submit button loading after submission
                // }}
                >
                    {({ isSubmitting, values }) => (
                        <Form className='form'>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="field-wrapper">
                                        <div className="form-group">
                                            <label htmlFor="f-name">First name</label>
                                            <Field type="text" className="form-control" placeholder='Enter your first name' id='f-name' name="firstName" />
                                        </div>
                                        <span className="error-msg"><ErrorMessage name="firstName" /></span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="field-wrapper">
                                        <div className="form-group">
                                            <label htmlFor="l-name">Last name</label>
                                            <Field type="text" className="form-control" placeholder='Enter your last name' id='l-name' name="lastName" />
                                        </div>
                                        <span className="error-msg"><ErrorMessage name="lastName" /></span>

                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="field-wrapper">
                                        <div className="form-group">
                                            <label htmlFor="userName">Username</label>
                                            <Field type="text" className="form-control" placeholder='Enter your first name' id='userName' name="userName" />
                                        </div>
                                        <span className="error-msg"><ErrorMessage name="userName" /></span>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="field-wrapper">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <Field type="email" className="form-control" placeholder='Enter your email address' id='email' name="email" />
                                        </div>
                                        <span className="error-msg"><ErrorMessage name="email" /></span>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="field-wrapper">
                                        <div className="form-group with-right-icon">
                                            <label htmlFor="password">Password</label>
                                            <Field type="password" className="form-control" placeholder='Create a password (8 or more characters)' id='password' name="password" />
                                            <LuEyeOff />
                                        </div>
                                        <span className="error-msg"><ErrorMessage name='password' /></span>
                                    </div>
                                </div>
                            </div>
                            <ul className="pass-feature">
                                <li className={values.password.match(/[a-z]/) ? 'active' : ''}><FaRegCheckCircle /> One lowercase character</li>
                                <li className={values.password.length >= 8 ? 'active' : ''}><FaRegCheckCircle /> 8 character minimum</li>
                                <li></li>
                                <li className={values.password.match(/[!@#$%^&*(),.?":{}|<>]/) ? 'active' : ''}><FaRegCheckCircle /> One special character</li>
                                <li className={values.password.match(/[0-9]/) ? 'active' : ''}><FaRegCheckCircle /> One number</li>
                                <li></li>
                                <li className={values.password.match(/[A-Z]/) ? 'active' : ''}><FaRegCheckCircle /> One uppercase character</li>
                            </ul>
                            <div className="form-footer">
                                {/* <button type="submit" disabled={isSubmitting} className='btn-style full'> Next  </button> */}
                                <Button type='submit' className='btn-style full'>Next</Button>
                                <p className="have-account medium">Already have an account?  <Link to="/sign-in" className="pink bold underline"> Log in</Link></p>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}