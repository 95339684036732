import React from "react";

import { FaCalendarAlt } from "react-icons/fa";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import JobsImg from '../assets/images/job-img-1.svg'
import smsIcon from '../assets/images/icons/sms-icon.svg'
import { Link } from "react-router-dom";
import videofile from '../assets/images/icons/video-file-wrapper.svg'
import liveIcon from '../assets/images/icons/live-icon.svg'
import refreshIconBlue from '../assets/images/icons/refresh-icon-blue.svg'
import checkFill from '../assets/images/icons/check-fill.svg'
import { useSelector } from "react-redux";
import { returnArray } from "../utils/utils";
import { parseImagePathToURL } from "../utils/globalFunctions";

export default function MyJobsListings({ tab = "all" }) {
    const jobs = useSelector(s => s.job.hiredJobs);
    const results = jobs.results ?? [];
    return (
        <div className="my-jobs-listings ">
            <div className="table-wrapper over_flow">
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'start', paddingLeft: 133 }}><strong className="white small">Brand Name</strong></th>
                            <th style={{ textAlign: 'start' }}><strong className="white small">Status</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            returnArray(results).length > 0 ?
                                returnArray(results).map((job, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="main-wrapper">
                                                <div className="img-wrapper">
                                                    <Link href="#">
                                                        <img src={returnArray(job?.image)?.[0] ? parseImagePathToURL(job?.image[0]) : JobsImg} alt="JobsImg" />
                                                    </Link>
                                                </div>
                                                <div className="text-wrapper">
                                                    <p className="dark"><Link to="#">{job?.jobTitle}</Link></p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="main-status-wrapper">
                                                <ul className="status-list">
                                                    <li className={job?.tiktokShopUrl ? "complete" : ""}><span className="status"></span> Tracking URL linked </li>
                                                    <li className="active"><span className="status"></span> Message Brand </li>
                                                    <li className="complete"><span className="status"></span>Payment Received  </li>
                                                    <li><span className="status"></span> Samples Received</li>
                                                    <li><span className="status"></span> Create Content</li>
                                                    <li><span className="status"></span> Complete </li>
                                                </ul>
                                                <div className="btns-wrapper">
                                                    <Link to={`/dashboard/messages?id=${job?.brandId}&jobId=${job?.id||job?._id}`} className="btn-style light"> <img src={smsIcon} alt="smsIcon" /> Message Brand</Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td colSpan="2" style={{ textAlign: 'center' }}>
                                        <p>No jobs found</p>
                                    </td>
                                </tr>
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}