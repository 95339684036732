import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function JobPostStayInLoop({ setCreatorInfo, creatorInfo = {}, setCanMove }) {
    // Define validation schema with Yup
    const validationSchema = Yup.object({
        phone: Yup.string()
            .required("Phone number is required")
            .matches(/^\d{10,15}$/, "Enter a valid phone number"), // Matches numbers between 10 to 15 digits
    });

    // Initialize Formik
    const {
        handleSubmit,
        setFieldValue,
        handleBlur,
        values,
        touched,
        errors,
        isValid,
    } = useFormik({
        initialValues: {
            phone: creatorInfo.phone || "",
        },
        validationSchema,
        validateOnMount: true, 
        onSubmit: (values) => {
            // Submit handler
            if (setCreatorInfo && typeof setCreatorInfo === "function") {
                setCreatorInfo(values);
            }
        },
    });

    // Update move state based on form validity
    useEffect(() => {
        setCanMove((prev) => ({ ...prev, 1: isValid }));
    }, [isValid, setCanMove]);

    useEffect(() => {
        setCreatorInfo(values);
    }, [values]);

    return (
        <div className="job-post-inner-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="modal-text-wrapper">
                    <h2>Stay in the Loop</h2>
                    <p className="medium medium-bold">
                        Your phone number lets us keep you updated on job opportunities, <br />
                        payments, and important notifications.
                    </p>
                </div>
                <div className="form-group">
                    <label htmlFor="phone" className="medium">
                        Phone Number
                    </label>
                    <PhoneInput
                        country={"us"}
                        value={values.phone}
                        onChange={(value) => setFieldValue("phone", value)} // Set value using Formik
                        onBlur={handleBlur}
                        inputProps={{
                            name: "phone",
                            className: `form-control blue-text ${touched.phone && errors.phone ? "is-invalid" : ""
                                }`,
                        }}
                    />
                    {touched.phone && errors.phone && (
                        <div className="invalid-feedback">{errors.phone}</div>
                    )}
                </div>
            </form>
        </div>
    );
}
