import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export default function SmallStatesChart() {
  const canvasRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    // Create gradient
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 0, 60);
    gradient.addColorStop(1, 'rgb(247 249 255)');
    gradient.addColorStop(0, 'rgb(205 238 251)');

    // Destroy previous chart instance if it exists
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Initialize Chart and store it in chartInstance ref
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [10, 20, 30, 40, 60],
        datasets: [
          {
            label: 'Videos per Week',
            backgroundColor: gradient,
            borderColor: '#00B9E6',
            borderWidth: 1,
            fill: true,
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
            data: [10, 50, 10, 40, 20, 80],
            lineTension: 0.4,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
              color: 'transparent',
            },
          },
          y: {
            ticks: {
              display: false,
              beginAtZero: true,
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
              color: 'transparent',
            },
          },
        },
      },
    });

    // Cleanup on unmount
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className="small-states-chart">
      <canvas ref={canvasRef} style={{ border: 'none' }} />
    </div>
  );
}
