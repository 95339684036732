import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
const toast = { error: () => { }, success: () => { } };
// Start Contract Slices
///////////////////////////////////////////////////

export const getContractAsyncThunk = createAsyncThunk(
  "Contract/getContractAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getContracts(params);
    return response?.data;
  })
);
export const getContractsAsyncThunk = createAsyncThunk(
  "Contract/getContractsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getContracts(params);
    return response?.data;
  })
);

export const createBrandContractAsyncThunk = createAsyncThunk(
  "Contract/createBrandContractAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.createBrandContract(data);
    if (response.status == 204) {
      toast.success("Contract Generated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const updateContractAsyncThunk = createAsyncThunk(
  "Contract/updateContractAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.updateContract({ id, data });
    if (response.status == 204) {
      toast.success("Contract Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getContractsAsyncThunk({ ...params, populate: "Contract_id", role: "Contract" })
    );
    // dispatch(getContractsByIdsAsyncThunk({ populate: "image,Contract_id", ...state.Contracts?.paramsForThunk?.getContractsByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);

export const deleteContractAsyncThunk = createAsyncThunk(
  "Contract/deleteContractAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteContract(id);
    if (response.status == 204) {
      toast.success("Contract Deleted Successfully!");
      let params = {};
      let state = getState().listings;
      if (state.search) params.name = state.search;
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(
        getContractsAsyncThunk({ ...params, populate: "Contract_id", role: "Contract" })
      );
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  contracts: {
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1,
    limit: 10,
  },

  contract: null,
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const ContractSlice = createSlice({
  name: "Contracts",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getContractsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.contracts = {
            ...action.payload,
            Contracts: state?.contracts?.results.concat(action?.payload?.results),
          };
        } else {
          state.contracts = action.payload;
          // "🚀 ~ file: ContractSlice.js:170 ~ .addCase ~ on.payload:", action.payload)
        }
      })
      .addCase(createBrandContractAsyncThunk.fulfilled, (state, action) => {
        state.contract = action.payload;
      })
      .addCase(getContractAsyncThunk.fulfilled, (state, action) => {
        state.contract = action.payload;
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getContractAsyncThunk,
            getContractsAsyncThunk,
            createBrandContractAsyncThunk,
            updateContractAsyncThunk,
            deleteContractAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default ContractSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  ContractSlice.actions;
