import React from "react";

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import SearchIcon from '../assets/images/icons/search-icon-wrapper.svg'
import filterIcon from '../assets/images/icons/filter-icon.svg'
import listIcon from '../assets/images/icons/list-icon.svg'
import gridIcon from '../assets/images/icons/grid-icon.svg'

const filterLabels = {
    "createdAt:desc": "Newest First",
    "createdAt:asc": "Oldest First",
    "jobTitle:asc": "A-Z",
    "jobTitle:desc": "Z-A",
}

export default function MyJobsPageHeader({ title="", jobsViewHandler, jobsView, handleSearchChange = () => { }, search = "",
    sortBy,
    setSortBy,
    containerFluid
}) {
    return (
        <div className="inner-page-header">
            <div className={`${containerFluid ? 'container-fluid' : 'container'} `}>
                <div className="row">   
                    <div className="col-md-4 align  -self-center">
                        <div className="inner-page-title-wrapper">
                            <h2>{title}</h2>
                        </div>
                    </div>
                    <div className="col-md-8 align-self-center">
                        <div className="right-menu-wrapper">
                            <div className="search-bar-wrapper">
                                <img src={SearchIcon} alt="SearchIcon" className="search-icon" />
                                <input onChange={handleSearchChange} value={search} type="text" className="form-control" placeholder="Search Jobs" />
                            </div>
                            <div className="filter-and-view-wrapper">
                                <div className="sort-by-wrapper">
                                    <strong>Sort by: </strong>
                                    <DropdownButton
                                        onSelect={setSortBy}
                                        id={`dropdown-button-drop-down`}
                                        drop={'down'}
                                        variant="secondary"
                                        title={'Newest'}
                                    >
                                        <Dropdown.Item eventKey="createdAt:desc">Newest First</Dropdown.Item>
                                        <Dropdown.Item eventKey="createdAt:asc">Oldest First</Dropdown.Item>
                                        <Dropdown.Item eventKey="jobTitle:asc">A-Z</Dropdown.Item>
                                        <Dropdown.Item eventKey="jobTitle:desc">Z-A</Dropdown.Item>
                                    </DropdownButton>
                                    <img src={filterIcon} alt="filterIcon" className="filter-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}