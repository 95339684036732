import React from "react";
import Modal from 'react-bootstrap/Modal';

import linkIcon from '../assets/images/icons/link-fill-icon.svg'
import barIcon from '../assets/images/bar-icon.svg'
import { FaInfoCircle } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";

export default function LinkTrackingUrlModal ({editModalCloseHandler, editModalState}) {
    return(
        <div className="link-tracking-url-mdoal-wrapper">
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                show={editModalState}
                className="link-tracking-modal custom-modal"
                onHide={editModalCloseHandler}
            >
                <Modal.Header editModalCloseHandler>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-wrappr">
                        <div className="img-wrapper">
                            <div className="img">
                                <img src={linkIcon} alt="linkIcon" />
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <h3>Link Tracking URL</h3>
                            <p className="medium medium-bold">To connect your tracking URL, please scan the QR code using your mobile device. This will automatically sync the tracking information with your account.</p>
                        </div>
                        <div className="scan-bar-icon-wrapper">
                            <img src={barIcon} alt="barIcon" className="bar-scan-icon" />
                        </div>
                        <div className="warning-wrapper">
                            <FaInfoCircle/>
                            <span>Once scanned, you’ll be redirected to your tracking URL and this step will be marked complete.</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button     to="#" className="btn-style large" >Continue</button>
                    <div className="or-wrapper">
                        <strong className="medium">OR enter the link manually</strong>
                    </div>
                    <div className="copy-link-wrapper">
                        <strong className="medium">https://www.tiktokshop.com/affiliate/track12345a</strong>
                        <MdContentCopy/>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}