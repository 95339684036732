import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function JobPostConnectTikTokShop({ setCreatorInfo, creatorInfo = {}, setCanMove }) {
    // Define validation schema with Yup
    const validationSchema = Yup.object({
        tikTokUserName: Yup.string()
            .required("TikTok Shop tikTokUserName is required")
            .matches(/^[a-zA-Z0-9_]+$/, "Only letters, numbers, and underscores are allowed"),
    });

    // Initialize Formik
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isValid,
    } = useFormik({
        initialValues: {
            tikTokUserName: creatorInfo.tikTokUserName || "",
        },
        validationSchema,
        validateOnMount: true, 
        onSubmit: (values) => {
            // Submit handler
            if (setCreatorInfo && typeof setCreatorInfo === "function") {
                setCreatorInfo(values);
            }
        },
    });
    // Update move state based on form validity
    useEffect(() => {
        setCanMove((prev) => ({ ...prev, 0: isValid }));
        console.log(isValid);
    }, [isValid, setCanMove]);
    useEffect(() => {
        setCreatorInfo(values);
    }, [values]);

    return (
        <div className="job-post-inner-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="modal-text-wrapper">
                    <h2>Let’s Connect Your TikTok Shop</h2>
                    <p className="medium medium-bold">
                        Adding your TikTok Shop tikTokUserName helps us find the best brand
                        <br />
                        opportunities that match your shop’s profile and style.
                    </p>
                </div>
                <div className="form-group">
                    <label htmlFor="tikTokUserName" className="medium">
                        TikTok Shop tikTokUserName
                    </label>
                    <input
                        id="tikTokUserName"
                        name="tikTokUserName"
                        type="text"
                        className={`form-control blue-text ${touched.tikTokUserName && errors.tikTokUserName ? "is-invalid" : ""
                            }`}
                        placeholder="Enter your TikTok Shop tikTokUserName"
                        value={values.tikTokUserName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.tikTokUserName && errors.tikTokUserName && (
                        <div className="invalid-feedback">{errors.tikTokUserName}</div>
                    )}
                </div>
            </form>
        </div>
    );
}
