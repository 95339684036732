import React, { useState } from "react";

import jobImg from '../assets/images/job-img-1.svg'
import mapIcon from '../assets/images/icons/map-icon.svg'
import clockFillIcon from '../assets/images/icons/clock-fill-icon.svg'
import videoFileIcon from '../assets/images/icons/video-file-wrapper.svg'
import liveIcon from '../assets/images/icons/live-icon.svg'
import statusIcon from '../assets/images/icons/status-icon.svg'
import chatIcon from '../assets/images/icons/chat-icon.svg'
import envelopIcon from '../assets/images/icons/envelop-fill-icon.svg'
import { Link } from "react-router-dom";
import TrackJobsStepsContent from "../components/TrackJobsStepsContent";
import LinkTrackingUrlModal from "../components/LinkTrackingUrlModal";

export default function TrackProjectPage() {

    const [currentStep, setCurrentStep] = useState(0);

    const handleNext = () => {
        if (currentStep < 6) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const isActive = (step) => step === currentStep;
    const isCompleted = (step) => step < currentStep;

    return (
        <div className="inner-page-wrapper active m-0">
            <div className="track-project-page">
                <div className="track-project-page-inner">
                    <div className="job-top-title-wrapper">
                        <div className="container large">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="title-wrapper">
                                        <h2 className="white">#1 Award Winning Hair Supplement & More!</h2>
                                        <div className="main-wrapper">
                                            <div className="img-wrapper">
                                                <img src={jobImg} alt="jobImg" />
                                            </div>
                                            <div className="content-wrapper">
                                                <div className="text">
                                                    <strong className="name medium white"><Link to="#" className="white">Burger King</Link></strong>
                                                    <div className="location">
                                                        <img src={mapIcon} alt="mapIcon" className="white-icon" />
                                                        <p className="medium medium-bold white"> United States</p>
                                                    </div>
                                                </div>
                                                <div className="text">
                                                    <p className="medium medium-bold m-0 white"><Link to="#">https://burgerking.com</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="top-bar-cards-wrapper">
                                        <div className="card-wrapper">
                                            <h3 className="white">12</h3>
                                            <div className="bottom">
                                                <img src={clockFillIcon} alt="clockFillIcon" className="white-icon" />
                                                <span className="ex-small white">Days to deadline</span>
                                            </div>
                                        </div>
                                        <div className="card-wrapper">
                                            <h3 className="white">0/5</h3>
                                            <div className="bottom">
                                                <img src={videoFileIcon} alt="videoFileIcon" className="white-icon" />
                                                <span className="ex-small white">Videos</span>
                                            </div>
                                        </div>
                                        <div className="card-wrapper">
                                            <h3 className="white">0/5</h3>
                                            <div className="bottom">
                                                <img src={liveIcon} alt="liveIcon" className="white-icon" />
                                                <span className="ex-small white">Live</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="track-project-steps-main-wrapper">
                                <div className={`step-name-wrapper ${isActive(0) ? 'active' : ''} ${isCompleted(0) ? 'complete' : ''}`}>
                                    <h3 className="num">1.</h3>
                                    <strong className="white">Link Tracking URL</strong>
                                </div>
                                <div className={`step-name-wrapper ${isActive(1) ? 'active' : ''} ${isCompleted(1) ? 'complete' : ''}`}>
                                    <h3 className="num">2.</h3>
                                    <strong className="white">Message Brand</strong>
                                </div>
                                <div className={`step-name-wrapper ${isActive(2) ? 'active' : ''} ${isCompleted(2) ? 'complete' : ''}`}>
                                    <h3 className="num">3.</h3>
                                    <strong className="white">Receive Samples</strong>
                                </div>
                                <div className={`step-name-wrapper ${isActive(3) ? 'active' : ''} ${isCompleted(3) ? 'complete' : ''}`}>
                                    <h3 className="num">4.</h3>
                                    <strong className="white">Receive Samples</strong>
                                </div>
                                <div className={`step-name-wrapper ${isActive(4) ? 'active' : ''} ${isCompleted(4) ? 'complete' : ''}`}>
                                    <h3 className="num">5.</h3>
                                    <strong className="white">Content Creation</strong>
                                </div>
                                <div className={`step-name-wrapper ${isActive(5) ? 'active' : ''} ${isCompleted(5) ? 'complete' : ''}`}>
                                    <h3 className="num">6.</h3>
                                    <strong className="white">Completion</strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="track-project-short-info-wrapper">
                        <div className="container large">
                            <div className="inner-section-wrapper">
                                <div className="text-wrapper">
                                    <div className="title">
                                        <img src={statusIcon} alt="statusIcon" />
                                        <span className="medium-bold">Status</span>
                                    </div>
                                    <p>In Progress</p>
                                </div>
                                <div className="text-wrapper">
                                    <div className="title">
                                        <img src={statusIcon} alt="statusIcon" />
                                        <span className="medium-bold">Next Action</span>
                                    </div>
                                    <p>Discuss Details with Brand</p>
                                </div>
                                <div className="text-wrapper">
                                    <div className="title">
                                        <img src={statusIcon} alt="statusIcon" />
                                        <span className="medium-bold">Content Type</span>
                                    </div>
                                </div>
                                <div className="text-wrapper">
                                    <div className="title">
                                        <img src={statusIcon} alt="statusIcon" />
                                        <span className="medium-bold">Amount</span>
                                    </div>
                                    <p>$2000</p>
                                </div>
                                <div className="text-wrapper">
                                    <div className="title">
                                        <img src={statusIcon} alt="statusIcon" />
                                        <span className="medium-bold">Start Date</span>
                                    </div>
                                    <p>09/27/2024</p>
                                </div>
                                <div className="text-wrapper">
                                    <div className="title">
                                        <img src={statusIcon} alt="statusIcon" />
                                        <span className="medium-bold">Completion Date</span>
                                    </div>
                                    <p>09/27/2024</p>
                                </div>
                                <div className="text-wrapper">
                                    <div className="title column-direction">
                                        <div className="img-wrapper">
                                            <img src={envelopIcon} alt="envelopIcon" className="white-icon" />
                                        </div>
                                        <span className="medium-bold">Message brand</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="track-project-main-content-section">
                        <div className="container large">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className={'main-steps-content-wrapper'}>
                                        <div className="main-title-wrapper">
                                            <h3>Track Your Project Progress</h3>
                                            <p className="medium medium-bold">Stay on top of every step from start to finish. Easily monitor your progress and take action to complete each task on time.</p>
                                        </div>

                                        <TrackJobsStepsContent currentStep={currentStep} isActive={isActive} isCompleted={isCompleted} />
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        </div>
                    </div>

                    <button onClick={handlePrevious} disabled={currentStep === 0}>
                        Previous
                    </button>
                    <button onClick={handleNext} disabled={currentStep === 6}>
                        Next
                    </button>

                    <div className="contact-us-in-trouble-wrapper">
                        <div className="container large">
                            <div className="inner-wrapper">
                                <div className="row">
                                    <div className="col-md-8 align-self-center">
                                        <div className="content-wrapper">
                                            <div className="img-wrapper">
                                                <img src={chatIcon} alt="chatIcon" />
                                            </div>
                                            <div className="text-wrapper">
                                                <strong className="medium">Having trouble?</strong>
                                                <p className="medium medium-bold">Feel free to contact us and we will always help you through the process</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 align-self-center">
                                        <div className="btn-wrapper">
                                            <a href="#" className="btn-style white-bg">Contact us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}