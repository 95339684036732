import React, { useState } from "react";
import notificationIcon from "../assets/images/icons/notification-icon.svg";
import settinglinelight from "../assets/images/icons/settings-light-icon.svg";
import checkfill from "../assets/images/icons/check-fill.svg";
import logoutlightIcon from "../assets/images/icons/logout-light-icon.svg";
import removeicon from "../assets/images/icons/remove-icon.svg";
import Google from "../assets/images/icons/google-icon.svg";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const setActiveTabHandler = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="settings-page">
      <div className="title">
        <span>Settings</span>
      </div>
      <div className="main-content">
        <div className="sec1">
          <div className="settings-menu">
            <button
              className={`menu-item ${activeTab === "tab1" ? "active" : ""}`}
              onClick={() => setActiveTabHandler("tab1")}
            >
              <div className="icon-parent">
                <img
                  src={notificationIcon}
                  alt="notification"
                  className="icon"
                  style={{ width: "12.64px", height: "16.46px" }}
                />
              </div>
              <span>Notification settings</span>
            </button>
            <button
              className={`menu-item ${activeTab === "tab2" ? "active" : ""}`}
              onClick={() => setActiveTabHandler("tab2")}
            >
              <div className="icon-parent">
                <img
                  src={settinglinelight}
                  alt="settinglinelight"
                  className="icon"
                  style={{ width: "16.67px", height: "16.67px" }}
                />
              </div>
              <span>Preferences</span>
            </button>
            <button
              className={`menu-item ${activeTab === "tab3" ? "active" : ""}`}
              onClick={() => setActiveTabHandler("tab3")}
            >
              <div className="icon-parent">
                <img
                  src={checkfill}
                  alt="checkfill"
                  className="icon"
                  style={{ width: "12.5px", height: "14.17px" }}
                />
              </div>
              <span>Password & Security</span>
            </button>
            <hr />
            <button
              className={`menu-item ${activeTab === "tab4" ? "active" : ""}`}
              onClick={() => setActiveTabHandler("tab4")}
            >
              <div className="icon-parent">
                <img
                  src={logoutlightIcon}
                  alt="logoutlightIcon"
                  className="icon"
                  style={{ width: "17.5px", height: "16.67px" }}
                />
              </div>
              <span>Log out</span>
            </button>
            <button
              className={`menu-item delete ${
                activeTab === "tab5" ? "active" : ""
              }`}
              onClick={() => setActiveTabHandler("tab5")}
            >
              <div className="icon-parent">
                <img
                  src={removeicon}
                  alt="removeicon"
                  className="icon"
                  style={{ width: "13.33px", height: "15px" }}
                />
              </div>
              <span style={{ color: "#DB2719" }}>Delete account</span>
            </button>
          </div>
        </div>
        <div className="sec2">
          {activeTab === "tab1" && (
            <div className="notification-settings">
              <h2 className="settings-tab-ui-head">Notification settings</h2>
              <hr />
              <div className="sec2-settings-context">
                <div className="setting">
                  <div>
                    <h3>Push Notification</h3>
                    <p>
                      Allow push notification to get updates on your account
                      activities and more.
                    </p>
                  </div>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
                <div className="notification-options">
                  <p>Show notifications for:</p>
                  <label>
                    <input type="checkbox" />
                    <span>Comments and replies.</span>
                  </label>
                  <label>
                    <input type="checkbox" />
                    <span>New messages.</span>
                  </label>
                  <label>
                    <input type="checkbox" />
                    <span>Job updates.</span>
                  </label>
                  <label>
                    <input type="checkbox" />
                    <span>System updates.</span>
                  </label>
                </div>
              </div>
              <div className="save-button-parent">
                <button className="save-button">Save</button>
              </div>
            </div>
          )}
          {activeTab==="tab3"&&(
            <div className="password-security-container">
            <h2 className="settings-tab-ui-head">Password & Security</h2>
            <hr className="settings-tab-ui-hr"/>
            <div className="sec2-setting-context">
            <div className="password-section">
              <label>Password</label>
              <input type="password" placeholder="********" className="col-lg-9 col-md-10" />
              <a href="/change-password" className="change-password-link">Change password</a>
            </div>
      
            <div className="other-login-methods">
              <h3>Other login methods</h3>
              {/* <div className="parent-div-text"> */}
              <span className="login-google">Log in with Google</span>
              <span className="login-google-subtext">Connect your Google account for easy and quick logins</span>
              <button className="connect-google-button">
                <img src={Google} alt="Google Icon" />
                <span>
                    Connect Google
                    </span>
              
              </button>
            {/* </div> */}
            </div>
      
            <div className="logout-all-devices">
              <h3>Log out of all devices</h3>
              <span className="col-lg-9 col-md-10">Log out of all active sessions across all devices, including your current session. It may take up to 30 minutes for other devices to be logged out.</span>
              <button className="logout-button">
                <span>Log out of all</span>
                </button>
            </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
