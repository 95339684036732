// import Image from 'next/image';
import emptyscreen from '../../assets/images/empty-img.png';

export default function Emptyscreen(){
    return(
        <div className="emptyscreen">
            <div>
              <img className="lazy" src={emptyscreen} alt="BMC" />            
            </div>
            <div>
                <h6>It’s nice to chat with someone.</h6>
                <p>Select a person from the left menu to continue your conversation.</p>
            </div>
        </div>
    )
}