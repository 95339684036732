import React from "react";
import { downloadFile } from "../../utils/globalFunctions";
import { FaFile } from "react-icons/fa6";
import fileImg from "../../assets/images/icons/file-img.svg";

export default function FileSentComponent({ file, content, time }) {
  return (
    <div
      className="file-sent-component"
      onClick={() => downloadFile(file, file.split("-").slice(1).join("-"))}
    >
      <div className="inner-wrapper">
        <div className="file-img">
          <img src={fileImg} alt="fileImg" />
          {/* <FaFile color="black" size={35} /> */}
        </div>
        <div className="text-wrapper">
          <strong className="d-block medium white">
            {content
                    .replace(/\s+/g, "")
                    .replace(/[^a-zA-Z0-9.-]/g, "")
                    .toLowerCase() === "file"?
                <></>:
                  "file." + file.split(".").pop()}
          </strong>
          <div className="bottom">
            {/* <span>2.3 mb</span> */}
            <span>{time}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
