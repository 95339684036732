import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function JobPostUGCRate({ setCreatorInfo, creatorInfo = {}, setCanMove }) {
    // Define validation schema with Yup
    const validationSchema = Yup.object({
        ugc_price: Yup.number()
            .typeError("UGC Rate must be a number")
            .required("UGC Rate is required")
            .min(1, "UGC Rate must be at least $1"),
    });

    // Initialize Formik
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isValid,
    } = useFormik({
        initialValues: {
            ugc_price: creatorInfo.ugc_price || "",
        },
        validationSchema,
        validateOnMount: true,
        onSubmit: (values) => {
            // Submit handler
            if (setCreatorInfo && typeof setCreatorInfo === "function") {
                setCreatorInfo(values);
            }
        },
    });

    // Update move state based on form validity
    useEffect(() => {
        setCanMove((prev) => ({ ...prev, 4: isValid })); // Adjust step index as needed
    }, [isValid, setCanMove]);

    useEffect(() => {
        setCreatorInfo(values);
    }, [values]);

    return (
        <div className="job-post-inner-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="modal-text-wrapper">
                    <h2>Set Your UGC Rate</h2>
                    <p className="medium medium-bold">
                        Setting your UGC rate helps brands understand your pricing for user-generated content jobs.
                    </p>
                </div>
                <div className="form-group with-label-icon">
                    <label htmlFor="ugc_price" className="medium">
                        UGC Rate
                    </label>
                    <span className="input-icon">$</span>
                    <input
                        id="ugc_price"
                        name="ugc_price"
                        type="text"
                        className={`form-control ${touched.ugc_price && errors.ugc_price ? "is-invalid" : ""}`}
                        placeholder="0"
                        value={values.ugc_price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                   
                </div>
                {touched.ugc_price && errors.ugc_price && (
                        <div className="invalid-feedback d-block" style={{marginTop:'-10px'}}>{errors.ugc_price}</div>
                    )}
            </form>
        </div>
    );
}
