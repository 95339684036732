import React, { useState } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { LuEyeOff } from "react-icons/lu";
import googleIcon from '../assets/images/icons/google-icon.svg';
import circularCrossIcon from '../assets/images/icons/circular-cross-icon.svg';

import LogoTopBar from '../components/LogoTopBar';
import InvalidEmailPassword from '../components/InvalidEmailPassword';
import LoginAsBrandBottomBar from '../components/LoginAsBrandBottomBar';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginAsyncThunk } from '../redux/pagesSlices/authSlice';


const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Must contain at least one lowercase character')
        .matches(/[A-Z]/, 'Must contain at least one uppercase character')
        .matches(/[0-9]/, 'Must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must contain at least one special character'),
});


export default function SignIn() {
    const d = useDispatch();
    const loading = useSelector(s => s.auth?.loadings?.userLoginAsyncThunk);
    const router = useNavigate();

    const handleSubmit = async (values, { resetForm }) => {
        await d(userLoginAsyncThunk({ email: values.email, password: values.password, router }));
    }
    return (
        <div className='sign-in-page'>
            <div className="inner-main-page">
                <LogoTopBar />
                <div className="inner-page-content">
                    <div className="login-page-content">
                        <div className="title-wrapper text-center">
                            <h2>👋 Welcome back</h2>
                            <p>Log in with your email or google account</p>
                        </div>
                        <div className="login-with-google-btn">
                            <a href="#" className="btn-style google-login-btn full large"><img src={googleIcon} alt="googleIcon" /> Log in with Google</a>
                        </div>
                        <div className="or-text">
                            <strong className='small'>OR</strong>
                        </div>

                        {/* {showError && <InvalidEmailPassword />} */}

                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={validationSchema}
                            // onSubmit={(values, { setSubmitting }) => {
                            //     console.log('Form submitted:', values);
                            //     setSubmitting(false); // Stop submit button loading after submission
                            // }}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, values, errors }) => (
                                <Form className='form transparent'>
                                    <div className="field-wrapper">
                                        <div className="form-group with-right-icon">
                                            <label htmlFor="email">Email</label>
                                            <Field type="text" className="form-control" placeholder='Enter your email address' id='email' name="email" />
                                            <img src={circularCrossIcon} alt="circularCrossIcon" style={{ cursor: 'pointer' }} />
                                        </div>
                                        <span className="error-msg"><ErrorMessage name="email" /></span>
                                    </div>
                                    <div className="field-wrapper">
                                        <div className="form-group with-right-icon">
                                            <label htmlFor="password">Password</label>
                                            <Field type="password" className="form-control" placeholder='Password' id='password' name="password" />
                                            <LuEyeOff />
                                        </div>
                                        <span className="error-msg"><ErrorMessage name='password' /></span>
                                    </div>
                                    <div className="form-bottom-text">
                                        <div className="custom-checkbox-wrapper">
                                            <label className="checkbox-label">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                                Stay signed in
                                            </label>
                                        </div>
                                        <a href="#" className="blue">Forgot Password?</a>
                                    </div>
                                    <div className="form-footer">
                                        {/* <button type="submit" disabled={isSubmitting} className='btn-style full'> Next  </button> */}
                                        <Button type='submit' disabled={loading} className='btn-style full'>{loading ? <Spinner /> : 'Next'}</Button>
                                        <p className="have-account medium">Already have an account?  <Link to="/sign-up" className="pink bold underline"> Sign up</Link></p>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </div>
                </div>
            </div>
            <LoginAsBrandBottomBar />
        </div>
    )
}