import React, { useState } from "react";
import unsplash from "../assets/images/unsplash.png";
import Editfill from "../assets/images/icons/Editfill.svg";
import Share from "../assets/images/icons/Share.svg";
import VideofileIcon from "../assets/images/icons/VideofileIcon.svg";
import Videofill from "../assets/images/icons/Videofill.svg";
import profileImg from "../assets/images/profileImg.png";
import MyProfileTabsComponent from "../components/MyProfileTabsComponent";
import logotiktok from "../assets/images/icons/logotiktok.svg"
import logofacebook from "../assets/images/icons/logofacebook.svg"
import logoinstagram from "../assets/images/icons/logoinstagram.svg"
import logoyt from "../assets/images/icons/logoyt.svg"
import PlayIcon from "../assets/images/icons/play-icon.svg"
import analytics from "../assets/images/icons/analytics.svg"
import dollarIcon from "../assets/images/icons/dollarIcon.svg"

    const userInfo = [
      { label: 'Location', value: 'United States' },
      { label: 'Age', value: '28' },
      { label: 'Gender', value: 'Male' },
      { label: 'Member Since', value: '09/22/2024' },
    ];
    
        const projects = [
            { id: 1, video: 'https://www.w3schools.com/html/mov_bbb.mp4' },
            { id: 2, video: 'https://www.w3schools.com/html/mov_bbb.mp4' },
            { id: 3, video: 'https://www.w3schools.com/html/mov_bbb.mp4' },
            { id: 4, video: 'https://www.w3schools.com/html/mov_bbb.mp4' },
            { id: 5, video: 'https://www.w3schools.com/html/mov_bbb.mp4' },
            { id: 6, video: 'https://www.w3schools.com/html/mov_bbb.mp4' },
            { id: 7, video: 'https://www.w3schools.com/html/mov_bbb.mp4' },
            { id: 8, video: 'https://www.w3schools.com/html/mov_bbb.mp4' },
        ];

const MyProfile = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  // Move `getButtonClass` inside `MyProfile` component
  const getButtonClass = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="my-profile-page" style={{ position: "relative" }}>
      <div className="top-image" style={{ position: "relative" }}>
        <img
          src={unsplash}
          alt="unsplash"
          style={{ height: "166px", width: "100%" }}
        />
      </div>
      <div className="buttons-div">
        <button className="btn1">
          <img src={Editfill} alt="Edit-fill" />
          <span> Edit profile</span>
        </button>
        <button className="btn2">
          <img src={Share} alt="Share" />
          <span> Share profile</span>
        </button>
      </div>
      <div className="profile-card-tabs-ui-parent">
      <div className="profile-card card col-md-4">
  <div className="profile-img-section text-center">
    <div className="img-div">
      <img src={profileImg} alt="profile-img" className="img-fluid" />
    </div>
    <span>John Cornors</span>
  </div>

  <div className="status">
    <div className="status-comp1">
      <span className="heading">$3k</span>
      <span className="text">GMV Per Video</span>
    </div>
    <div className="hr"></div>
    <div className="status-comp1">
      <span className="heading">$60k</span>
      <span className="text">GMV</span>
    </div>
  </div>

  <div className="info">
    <table className="user-info-table">
      <tbody>
        {userInfo.map((item, index) => (
          <tr key={index}>
            <td>{item.label}</td>
            <td className="user-info-value">{item.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>

  <div className="social-icons">
    <div className="img-parent">
    <img src={logotiktok} alt="tiktok-logo" style={{width:"12.99px", height:"15px"}}/>
    </div>
    <div className="img-parent">
    <img src={logofacebook} alt="facebook-logo" style={{width:"15px", height:"15px"}}/>
    </div>
    <div className="img-parent">
    <img src={logoinstagram} alt="instagram-logo" style={{width:"15px", height:"15px"}}/>
    </div>
    <div className="img-parent">
    <img src={logoyt} alt="youtube-logo" style={{width:"21.5px", height:"15px"}}/>
    </div>
  </div>
</div>

        <div className="bottom-ui-tabs">
          <MyProfileTabsComponent
            activeTab={activeTab}
            getButtonClass={getButtonClass}
          />

          {activeTab === "tab1" && (
            <div className="tab-ui">
              <div className="sec1">
                <span>What’s Unique About Me</span>
                <p>
                  A TikTok creator is an individual who produces engaging,
                  creative content on the TikTok platform, utilizing its
                  short-form video format to entertain, inform, or influence
                  their audience. These creators typically focus on niche areas
                  like lifestyle, fashion, fitness, beauty, comedy, or
                  education, building a loyal following through their unique
                  style and personality. TikTok creators are skilled at
                  leveraging trending sounds, challenges, and hashtags to
                  maximize their content’s reach and impact, often collaborating
                  with brands to promote products in a fun, authentic way.
                  Creators on TikTok have the ability to drive high engagement
                  through their relatable, innovative content, making them
                  valuable partners for brands looking to tap into specific
                  target audiences. They often use the platform’s various tools,
                  such as live streaming and shoppable videos, to connect with
                  followers and monetize their content effectively.
                </p>
              </div>
              <div className="sec2">
                <div className="sec2-comp1">
                  <span>Content type</span>
                  <div className="sec2-comp1-btns">
                    <button>
                      <img src={VideofileIcon} alt="VideofileIcon" />
                      <span>Video</span>
                    </button>
                    <button style={{ background: "#F7EFF5" }}>
                      <img src={Videofill} alt="Videofill" />
                      <span>Live</span>
                    </button>
                  </div>
                </div>
                <div className="sec2-comp2">
                  <span>Categories</span>
                  <div className="sec2-comp2-btns" style={{ gap: "5.85px" }}>
                    <button>
                      <span>Health & Wellness</span>
                    </button>
                    <button>
                      <span>Sport & Fitness</span>
                    </button>
                    <button>
                      <span>Lifestyle</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === "tab2" && (
  <div className="project-section">
    <h4 className="section-title">Some projects I've worked on</h4>
    <div className="row">
      {[...Array(10)].map((_, index) => (
        <div className="col-5 project-card" key={index}>
          <div className="project-video-wrapper">
            <video className="project-video" src="default-video.mp4" controls muted />
            <div className="play-icon-overlay">
              <img src={PlayIcon} alt="Play" className="play-icon" />
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)}
          {activeTab === "tab3" && (
  <div className="analyticANDinsight-section">
    <h4 className="section-title">Analytics</h4>
    <div className="row g-3">
  {[...Array(8)].map((_, index) => (
    <div className="col-lg-3 col-md-3 col-sm-6 project-card" key={index}>
      <div className="icon-wrapper">
        <img src={analytics} alt="analytics" />
      </div>
      <div className="context-sec">
        <span className="text1">Video GPM</span>
        <span className="text2">$21.78</span>
        <span className="text3">$21.78 Min GPM</span>
      </div>
    </div>
  ))}
</div>
  </div>
)}
          {activeTab === "tab4" && (
  <div className="services-section">
    <div className="services-sec-comp1">
  <h4 className="section-title">Analytics</h4>
  <div className="row g-3">
    {[...Array(4)].map((_, index) => (
      <div className="col-lg-3 col-md-3 col-sm-6 project-card" key={index}>
        <div className="icon-wrapper">
          <img src={analytics} alt="analytics" />
        </div>
        <div className="context-sec">
          <span className="text1">Video GPM</span>
          <span className="text2">$21.78</span>
          <span className="text3">$21.78 Min GPM</span>
        </div>
      </div>
    ))}
  </div>
  </div>
    <div className="services-sec-comp2">
  <h4 className="section-title">Live</h4>
  <div className="row g-3">
    {[...Array(4)].map((_, index) => (
      <div className="col-lg-3 col-md-3 col-sm-6 project-card" key={index}>
        <div className="icon-wrapper">
          <img src={dollarIcon} alt="dollarIcon" />
        </div>
        <div className="context-sec">
          <span className="text1">Video GPM</span>
          <span className="text2">$21.78</span>
        </div>
      </div>
    ))}
  </div>
  </div>
</div>

)}
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
