import React from "react";
import settingIcon from '../assets/images/icons/settings-light-icon.svg'
import xIcon from '../assets/images/icons/x-icon.svg'
import avatarIcon from '../assets/images/icons/avatar-icon.svg'
import NoNotificationComponent from "./NoNotificationComponent";

export default function NotificationComponent({ hasNotification, showNotification, hideNotificationsHandler, notificationRef }) {
    return (
        <div className={`notification-main-wrapper ${showNotification ? 'show' : ''}`} ref={notificationRef}>
            <div className="title-wrapper">
                <strong className="dark large medium-bold">Notifications</strong>
                <ul className="list">
                    <li><a href="#"><img src={settingIcon} alt="settingIcon" /></a></li>
                    <li><a href="#" onClick={hideNotificationsHandler}><img src={xIcon} alt="xIcon" /></a></li>
                </ul>
            </div>
            <div className="notification-list-wrapper">
                {hasNotification ?
                    <>
                        <div className="notifications-wrapper">
                            <div className="inner-wrapper">
                            <div className="notification-list-title-wrapper ">
                                <span>TODAY</span>
                                <div className="right-menu-wrapper">
                                    <span className="small">Show only unread</span>
                                    <a href="#" className="blue">Mark all as read</a>
                                </div>
                            </div>
                            <div className="all-listings-wrapper">
                                <div className="notification-wrapper notifications">
                                    <div className="avatar-icon">
                                        <a href="#">
                                            <img src={avatarIcon} alt="avatarIcon" /></a>
                                    </div>
                                    <div className="text-wrapper">
                                        <strong className="dark"> <a href="#">New Job:  Top Trainer Supplements looking for creators to make 10 videos per month</a></strong>
                                        <span className="time">2 hours ago</span>
                                    </div>
                                </div>
                            </div>
                            <div className="notification-list-title-wrapper ">
                                <span>OLDER</span>
                            </div>
                            </div>
                          
                            
                        </div>
                        <div className="view-all-notifications-btn-wrapper">
                                <button className="view-all-notification-btn">View all notifications</button>
                            </div>

                    </>
                    :
                    <>
                        <NoNotificationComponent />
                    </>
                }
            </div>
        </div>
    )
}