import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function JobPostWhatMakesYou({ setCreatorInfo, creatorInfo = {}, setCanMove }) {
    // Define validation schema with Yup
    const validationSchema = Yup.object({
        bio: Yup.string()
            .required("This field is required")
            .min(20, "Your bio must be at least 20 characters")
            .max(300, "Your bio cannot exceed 300 characters"),
    });

    // Initialize Formik
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isValid,
    } = useFormik({
        initialValues: {
            bio: creatorInfo.bio || "",
        },
        validationSchema,
        validateOnMount: true, 
        onSubmit: (values) => {
            // Submit handler
            if (setCreatorInfo && typeof setCreatorInfo === "function") {
                setCreatorInfo(values);
            }
        },
    });

    // Update move state based on form validity
    useEffect(() => {
        setCanMove((prev) => ({ ...prev, 2: isValid }));
    }, [isValid, setCanMove]);

    useEffect(() => {
        setCreatorInfo(values);
    }, [values]);

    return (
        <div className="job-post-inner-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="modal-text-wrapper">
                    <h2>Tell Us What Makes You, You!</h2>
                    <p className="medium medium-bold">
                        Share something special about your personality or content style. <br />
                        Brands love to know what makes creators stand out.
                    </p>
                </div>
                <div className="form-group">
                    <label htmlFor="bio" className="medium">
                        What’s unique about you
                    </label>
                    <textarea
                        name="bio"
                        id="bio"
                        className={`form-control blue-text ${touched.bio && errors.bio ? "is-invalid" : ""}`}
                        placeholder="Tell us what’s unique about you"
                        rows={4}
                        value={values.bio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    ></textarea>
                    {touched.bio && errors.bio && (
                        <div className="invalid-feedback">{errors.bio}</div>
                    )}
                </div>
            </form>
        </div>
    );
}
