import React, { useEffect, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { returnArray } from "../utils/utils";

import videoFileIcon from "../assets/images/icons/video-file-wrapper.svg";
import refreshIcon from "../assets/images/icons/refresh-icon.svg";
import pointerIcon from "../assets/images/icons/pointer-icon.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";

export default function JobPostShopRates({ setCreatorInfo, creatorInfo = {}, setCanMove }) {
    // Validation schema for prices
    const validationSchema = Yup.object({
        videoPrices: Yup.array().of(
            Yup.object({
                duration: Yup.string().required("duration is required"),
                durationCount: Yup.number().required('duration count is required'),
                count: Yup.number().required('count is required'),
                price: Yup.number().min(0, "Price must be at least 0").required('price is required'),
            })
        ),
        livePrices: Yup.array().of(
            Yup.object({
                duration: Yup.string().required("duration is required"),
                durationCount: Yup.number().required('duration count is required'),
                count: Yup.number().required('count is required'),
                price: Yup.number().min(0, "Price must be at least 0").required('price is required'),
            })
        ),
    });

    // Initialize Formik
    const {
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
        isValid,
        handleSubmit,
    } = useFormik({
        initialValues: {
            videoPrices: returnArray(creatorInfo.videoPrices),
            livePrices: returnArray(creatorInfo.livePrices),
        },
        validationSchema,
        validateOnMount: true, 
        onSubmit: (formValues) => {
            if (setCreatorInfo) setCreatorInfo(formValues);
        },
    });

    // Sync state for navigation control
    useEffect(() => {
        setCanMove((prev) => ({ ...prev, 5: isValid })); // Adjust step index as necessary
    }, [isValid, setCanMove]);

    // UseCallback to memoize the setCreatorInfo effect
    const updateCreatorInfo = useCallback(() => {
        setCreatorInfo(values);
    }, [values]);

    useEffect(() => {
        updateCreatorInfo();
    }, [updateCreatorInfo]);

    // Memoized renderPrices function
    const renderPrices = useCallback((prices, fieldName) => {
        return prices.map((item, index) => (
            <div className="list" key={`${fieldName}-${index}`}>
                <span>
                    <span className="bold">{item.count}</span> {item.durationCount > 1 ? `${item.duration}s` : item.duration} per{" "}
                    {item.durationCount > 1 ? `${item.durationCount} months` : "month"}
                </span>
                <div className="price-wrapper">
                    <div className="price">
                        <span>$</span>
                        <input
                            type="number"
                            name={`${fieldName}[${index}].price`}
                            placeholder="Enter price"
                            value={values[fieldName][index].price || 0}
                            onChange={(e) => {
                                const updatedPrices = [...values[fieldName]];
                                updatedPrices[index].price = e.target.value;
                                setFieldValue(fieldName, updatedPrices);
                            }}
                            onBlur={handleBlur}
                            className={
                                touched[fieldName]?.[index]?.price && errors[fieldName]?.[index]?.price
                                    ? "is-invalid"
                                    : ""
                            }
                        />
                    </div>
                    {touched[fieldName]?.[index]?.price && errors[fieldName]?.[index]?.price && (
                        <div className="invalid-feedback">{errors[fieldName][index].price}</div>
                    )}
                    <img src={pointerIcon} alt="pointerIcon" className="pointer-icon" />
                </div>
            </div>
        ));
    }, [values, touched, errors, setFieldValue, handleBlur]);

    return (
        <div className="job-post-inner-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="modal-text-wrapper">
                    <h2>Here’s What We Suggest for TikTok <br /> Shop Rates</h2>
                    <p className="medium medium-bold">
                        Based on your shop’s GMV, we’ve got recommended rates for TikTok Shop videos <br /> and lives to help you set competitive prices.
                        <button className="custom-btn blue underline semi_bold" type="button">
                            See how
                        </button>
                    </p>
                </div>
                <div className="shop-rates-list-main-wrapper">
                    <div className="main-wrapper">
                        <div className="title-wrapper">
                            <div className="title">
                                <img src={videoFileIcon} alt="Video Prices" />
                                <strong className="medium">Video Prices</strong>
                            </div>
                            <img src={refreshIcon} alt="Refresh" />
                        </div>
                        <div className="main-list-wrapper">{renderPrices(returnArray(creatorInfo.videoPrices), "videoPrices")}</div>
                    </div>
                    <div className="main-wrapper">
                        <div className="title-wrapper">
                            <div className="title">
                                <img src={liveIcon} alt="Live Prices" />
                                <strong className="medium">
                                    Live Prices <span>(At least 1 hour)</span>
                                </strong>
                            </div>
                            <img src={refreshIcon} alt="Refresh" />
                        </div>
                        <div className="main-list-wrapper">{renderPrices(returnArray(creatorInfo.livePrices), "livePrices")}</div>
                    </div>
                </div>
                {/* <button type="submit" className="custom-btn primary">Save Rates</button> */}
            </form>
        </div>
    );
}
