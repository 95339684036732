import React from "react";

import tikTokShopPreviewImg from '../assets/images/tiktok-shop-rate-preview-img.jpeg'
import playIcon from '../assets/images/icons/play-icon.svg'

export default function JobPostShopRatesSamplePreview() {
    return (
        <div className="job-post-inner-wrapper">
            <div className="modal-text-wrapper">
                <h2>How we calculate TikTok Shop Rates</h2>
            </div>
            <div className="tiktok-shop-rate-preview-wrapper">
                <img src={playIcon} alt="playIcon" className="play-icon" />
                <div className="img-wrapper">
                <img src={tikTokShopPreviewImg} alt="tikTokShopPreviewImg" className="tiktok-shop-rate-preview-img" />
                </div>
            </div>
        </div>
    )
}