import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authenticateAsyncThunk } from '../redux/pagesSlices/authSlice';
import LoaderComponent from '../components/LoaderComponent';
import JobPostModalComponent from '../components/JobPostModalComponent';

export default function Authenticated({ Component }) {
  const d = useDispatch();
  const auth = useSelector(s => s.auth);
  const loading = auth?.loadings?.authenticateAsyncThunk;
  const user = auth?.user;
  const creator = auth?.creator;
  const isScreenLock = auth?.isScreenLock;

  useEffect(() => {
    if (!user)
      d(authenticateAsyncThunk());
  }, [user, d]);

  if (loading) return <LoaderComponent />

  if (!user) return <Navigate to="/sign-in" />

  const isProfileCompleted = Number(creator?.steps) >= 4;

  if (!isProfileCompleted || isScreenLock) {
    return <div>
      <JobPostModalComponent
        show={!isProfileCompleted || isScreenLock}
        onHide={() => { }}
        modalCloseHandler={() => { }}
      />
    </div>
  }
  return (
    <Component />
  )
}