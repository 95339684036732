import React, { useState } from "react";
import { Link } from "react-router-dom";

import JobsImg from "../assets/images/job-img-1.svg";
import videoIcon from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import eye from "../assets/images/icons/eye.svg";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import shopIcon from "../assets/images/icons/shop-icon.svg";
import removeIcon from "../assets/images/icons/remove-icon.svg";
import targetIcon from "../assets/images/icons/target-icon.svg";
import { parseImagePathToURL } from "../utils/globalFunctions";

import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useSelector } from "react-redux";
import { returnArray } from "../utils/utils";
import { dateFormat } from "../utils/format";
import WithDrawlProposalModal from "./WithDrawlProposalModal";

export default function MyProposalsListing({ application }) {
  const [activeWithdrawlModal, setActiveWuthDrawlModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const withDrawlModalShowHandler = (id) => {
    setDeleteId(id);
    setActiveWuthDrawlModal(true);
  };
  const withDrawlModalCloseHandler = () => {
    setActiveWuthDrawlModal(false);
    setDeleteId(null);
  };

  return (
    <>
      <tr>
        <td>
          <div className="main-wrapper">
            <div className="img-wrapper">
              <Link href="#">
                <img src={returnArray(application?.jobId?.image)?.[0] ? parseImagePathToURL(application?.jobId?.image[0]) : JobsImg} alt="JobsImg" />
              </Link>
            </div>
            <div className="text-wrapper">
              <p className="dark">
                <Link
                  to={`/dashboard/proposals/${
                    application?.id ?? application?.id
                  }`}
                >
                  {application?.jobId?.jobTitle}
                </Link>
              </p>
            </div>
          </div>
        </td>
        <td>
          <p className="dark">
            <Link to="#">{application?.jobId?.brandName}</Link>
          </p>
        </td>
        <td>
          <ul className="proposal-type-list">
            {returnArray(application?.jobId?.types).map((type, index) => (
              <li key={index}>
                <img
                  src={type === "video" ? videoIcon : liveIcon}
                  alt="targetIcon"
                />
                {type}
              </li>
            ))}
          </ul>
        </td>
        <td>
          <div
            className={`applied-status ${
              application?.status === "approved"
                ? "hired"
                : application?.status === "applied"
                ? "applied"
                : application?.status === "reject"
                ? "rejected"
                : "rejected"
            }`}
          >
            <span className="icon"></span>
            <p className="dark">{application?.status}</p>
          </div>
        </td>
        <td>
          <p className="dark">{dateFormat(application?.createdAt)}</p>
        </td>
        <td>
          <div className="action-btn-wrapper">
            <DropdownButton
              id={`dropdown-button-drop-down`}
              drop={"down"}
              variant="secondary"
              className="custom-dropdown three-dots-line"
              title={<PiDotsThreeOutlineVerticalFill />}
            >
              <div className="title">
                <strong className="dark">Action</strong>
              </div>
              <Dropdown.Item
                eventKey="1"
                as={Link}
                to={`/dashboard/proposals/${
                  application?.id ?? application?.id
                }`}
              >
                <img src={eye} alt="eye" /> View job details
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="1"
                onClick={() => withDrawlModalShowHandler(application?.id)}
              >
                <img src={removeIcon} alt="removeIcon" /> Withdraw proposal
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="1"
                as={Link}
                to={`/dashboard/proposals/${
                  application?.id ?? application?._id
                }/track`}
                href=""
              >
                {" "}
                <img src={targetIcon} alt="targetIcon" /> Track proposal
              </Dropdown.Item>
              <Dropdown.Item eventKey="2">
                <img src={shopIcon} alt="shopIcon" /> View brand profile
              </Dropdown.Item>
            </DropdownButton>
            <Link
              to={`/dashboard/proposals/${application?.id ?? application?._id}`}
            >
              <div className="icon-wrapper rounded medium">
                <img src={eyeIcon} alt="eyeIcon" />
              </div>
            </Link>
          </div>
        </td>
      </tr>
      {activeWithdrawlModal && (
        <WithDrawlProposalModal
          withDrawlModalCloseHandler={withDrawlModalCloseHandler}
          activeWithdrawlModal={activeWithdrawlModal}
          deleteId={deleteId}
          callBack={withDrawlModalCloseHandler}
        />
      )}
    </>
  );
}
