import React from "react";

export default function DashboardMyMessages({ dashboardMyMessagesArray }) {
    return (
        <div className="dashboard-my-messages-main-wrapper">
            {dashboardMyMessagesArray.map((message, index) => (
                <div className={`message-wrapper ${message.is_active ? 'active' : ''}  `} key={index}>
                    <div className="img-wrapper">
                        <img src={message.personImg} alt="avatar-img" />
                    </div>
                    <div className="text-wrapper">
                        <strong className="dark medium semi_bold">{message.name}</strong>
                        <span>{message.message}</span>
                        {
                            message.totalNotificationNum > 0 && 
                            <strong className="notifications-num white">{message.totalNotificationNum}</strong>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}