import React from "react";
import { isImageURL, parseImagePathToURL } from "../utils/globalFunctions";
import SentImageComponent from "./Chat/SentImageComponent";
import FileSentComponent from "./Chat/FileSentComponent";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDots } from "react-icons/bs";
export default function SentMessage({ message, handleDeleteMessage }) {
  const messageDate = new Date(message?.createdAt);
  const hours = messageDate.getHours();
  const minutes = messageDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  function decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  return (
    <div className="message-wrapper sent-msg">
      {message?.file && (
        <>
          <div className="uploaded-items-list">
            {isImageURL(message?.file) && (
              <SentImageComponent
                imageSrc={parseImagePathToURL(message?.file)}
              />
            )}
            {message?.content !== "voice message" &&
              !isImageURL(message?.file) && (
                <FileSentComponent
                  file={message?.file}
                  content={message?.content}
                  time={formattedTime}
                />
              )}
          </div>
        </>
      )}
      {(!message?.file || message?.content === "voice message") && (
        <div className="message">
          {message?.content === "voice message" ? (
            <audio controls>
              <source
                src={parseImagePathToURL(message.file)}
                type="audio/wav"
              />
              Your browser does not support the audio element.
            </audio>
          ) : (
            <p
              dangerouslySetInnerHTML={{ __html: decodeHTML(message?.content) }}
            ></p>
          )}
          <span className="tile">{formattedTime}</span>
          <DropdownButton
            id={`dropdown-button-drop-down`}
            drop={"down"}
            variant="secondary"
            title={<BsThreeDots />}
            className="custom-dropdown three-dots-line action-dropdown"
          >
            {/* <Dropdown.Item eventKey="1">Report chat </Dropdown.Item> */}
            <Dropdown.Item eventKey="2" onClick={handleDeleteMessage}>
              Delete chat
            </Dropdown.Item>
          </DropdownButton>
        </div>
      )}
    </div>
  );
}
