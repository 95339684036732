import React, { useState } from "react";
import leftCheveIcon from "../assets/images/icons/left-chev-icon.svg";

export default function SideBarComponent({
    selectedFilters = { categories: [], promotionType: [], preferredGender: [] },
    setSelectedFilters = () => { },
    sideBarOpen, sideBarOpenHandler }) {
    const [activeMenu, setActiveMenu] = useState(null);


    const toggleMenu = (menu) => {
        setActiveMenu(activeMenu === menu ? null : menu);
    };

    const handleFilterChange = (category, value) => {
        setSelectedFilters((prevFilters) => {
            const currentValues = prevFilters[category];
            const isSelected = currentValues.includes(value);
            return {
                ...prevFilters,
                [category]: isSelected
                    ? currentValues.filter((item) => item !== value)
                    : [...currentValues, value],
            };
        });
    };

    const clearAllFilters = () => {
        setSelectedFilters({
            categories: [],
            promotionType: [],
            preferredGender: [],
        });
    };

    const categoriesArray = [
        "All",
        "Beauty & Care",
        "Business & Finance",
        "Fashion & Style",
        "Food & Drinks",
        "Gaming & Tech",
        "Health & Wellness",
        "Home & Garden",
        "Kids & Parenting",
        "Lifestyle",
        "Outdoors & Nature",
        "Pets",
        "Sport & Fitness",
        "Travel",
    ];
    const promotionTypeArray = ["Video", "Live"];
    const preferredGenderArray = ["All", "Male", "Female", "Other"];

    return (
        <div className={`sidebar-main-wrapper ${sideBarOpen ? "active" : ""}`}>
            <div className="sidebar-top-bar">
                <div className="filter-text-wrapper" onClick={sideBarOpenHandler}>
                    <strong className="medium">Filter</strong>
                    <img src={leftCheveIcon} alt="leftCheveIcon" className="left-chev-icon" />
                </div>
                {/* <button onClick={clearAllFilters} className="blue semi_bold">
                    Clear All
                </button> */}
            </div>
            <div className="sidebar-main-listings-wrapper">
                <div className="listing-wrapper">
                    <ul className="listings listing-category-titles">
                        <li
                            onClick={() => toggleMenu("categories")}
                            className={activeMenu === "categories" ? "active" : ""}
                        >
                            Categories
                            <ul className="sub-menu-list">
                                {categoriesArray.map((category, index) => (
                                    <li key={index}>
                                        <div className="filter-category">
                                            <div className="custom-checkbox-wrapper category-checkbox">
                                                <label className="checkbox-label">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedFilters.categories.includes(category)}
                                                        onChange={() =>
                                                            handleFilterChange("categories", category)
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="sub-category-name">{category}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li
                            onClick={() => toggleMenu("promotionType")}
                            className={activeMenu === "promotionType" ? "active" : ""}
                        >
                            Promotion type
                            <ul className="sub-menu-list">
                                {promotionTypeArray.map((promotionType, index) => (
                                    <li key={index}>
                                        <div className="filter-category">
                                            <div className="custom-checkbox-wrapper category-checkbox">
                                                <label className="checkbox-label">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedFilters.promotionType.includes(
                                                            promotionType
                                                        )}
                                                        onChange={() =>
                                                            handleFilterChange("promotionType", promotionType)
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="sub-category-name">{promotionType}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li
                            onClick={() => toggleMenu("preferredGender")}
                            className={activeMenu === "preferredGender" ? "active" : ""}
                        >
                            Preferred Gender
                            <ul className="sub-menu-list">
                                {preferredGenderArray.map((preferredGender, index) => (
                                    <li key={index}>
                                        <div className="filter-category">
                                            <div className="custom-checkbox-wrapper category-checkbox">
                                                <label className="checkbox-label">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedFilters.preferredGender.includes(
                                                            preferredGender
                                                        )}
                                                        onChange={() =>
                                                            handleFilterChange("preferredGender", preferredGender)
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="sub-category-name">{preferredGender}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
