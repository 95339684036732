import React from 'react'

export default function DashboardServicePrice({ servicePriceVideoCardsArray, servicePriceLiveCardsArray }) {
    return (
        <div className="service-prices-main-wrapper">
            <div className="videos-price-list">
                {servicePriceVideoCardsArray.map((video, index) => (
                    <div className="service-price" key={index}>
                        <div className="text">
                            <strong className="dark semi_bold">{video.count} Videos </strong>
                            <span className="ex_small">Per Month</span>
                        </div>
                        <div className="text">
                            <strong className="dark ">${video.price}</strong>
                        </div>
                    </div>
                ))}
            </div>
            <div className="live-price-list">
                {servicePriceLiveCardsArray.map((live, index) => (
                    <div className="service-price" key={index}>
                        <div className="text">
                            <strong className="dark semi_bold">{live.count} Lives </strong>
                            <span className="ex_small">Per Month</span>
                        </div>
                        <div className="text">
                            <strong className="dark ">${live.price}</strong>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}