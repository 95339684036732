import React, { useEffect } from "react";

import jobImg from '../assets/images/job-img.svg'
import brandImg from '../assets/images/brand-img.svg'
import videoFileIcon from '../assets/images/icons/video-file-wrapper.svg'
import liveIcon from '../assets/images/icons/live-icon.svg'
import applyNowRightArrow from '../assets/images/icons/apply-now-right-arrow.svg'
import { returnArray } from "../utils/utils";
import { parseImagePathToURL } from "../utils/globalFunctions";
import JobCardComponent from "./JobCardComponent";
import { HiArrowRight } from "react-icons/hi";
import CreatorDashboardStatesComponent from "../components/CreatorDashboardStatesComponent";
import jobImage from '../assets/images/job-img.svg'
import jobImage1 from '../assets/images/job-img-1.svg'
import jobImage2 from '../assets/images/job-img-2.svg'
import brandImage from '../assets/images/brand-img.svg'
import brandImage1 from '../assets/images/brand-img-1.svg'
import brandImage2 from '../assets/images/brand-img-2.svg'
import { useDispatch, useSelector } from "react-redux";
import { getRecommendedJobsAsyncThunk } from "../redux/pagesSlices/jobSlice";
import { Link } from "react-router-dom";

export default function RecommendedJobCardComponent() {
    const loading = useSelector(s => s.job?.loadings?.getRecommendedJobsAsyncThunk);
    const profile = useSelector(s => s.auth?.profile);
    console.log("profile", profile);
    const jobs = useSelector(s => s.job?.recommendedJobs);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRecommendedJobsAsyncThunk({
            populate: 'brandId',
            categories: returnArray(profile?.categories)?.length > 0 ? profile?.categories : [],
        }))
    }, [dispatch]);
    const jobsArray = returnArray(jobs?.results);
    return (
        <div className="recomended-jobs-section">
            <div className="container ex-large">
                <div className="title-wrapper">
                    <h2>Recommended Jobs</h2>
                </div>
                <div className="jobs-section">
                    <div className="row">
                        {jobsArray.length === 0 && !loading && <div className="col-md-12 text-center">No jobs found</div>}
                        {jobsArray.map((job, index) => (
                                <JobCardComponent job={job} jobImg={job.jobImg} jobTitle={job.jobTitle} brandImg={job.brandImg} brandName={job.brandName} jobPostedTime={job.jobPostedTime} key={index} />
                     
                        ))}
                    </div>
                </div>
                <div className="more-job-btn-wrapper text-center mt-4">
                    <Link to={"/dashboard/find-jobs"} className="btn-style large_font">View more jobs <HiArrowRight /></Link>
                </div>
            </div>
        </div>
    )
}   