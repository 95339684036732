import React, { useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { MdOutlineAttachment } from "react-icons/md";
import LinkTrackingUrlModal from "./LinkTrackingUrlModal";
import { FaEnvelope } from "react-icons/fa6";
import confirmPayment from '../assets/images/icons/subtract-icon.svg'
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import videofile from '../assets/images/icons/video-file-wrapper.svg'
import liveIcon from '../assets/images/icons/live-icon.svg'
import { TbRefresh } from "react-icons/tb";
import { HiMiniInformationCircle } from "react-icons/hi2";

export default function TrackJobsStepsContent({ currentStep, isCompleted, isActive }) {
    const [editModalState, setEditModalState] = useState(false);

    const editModalOpenHandler = () => {
        setEditModalState(true)
    }
    const editModalCloseHandler = () => {
        setEditModalState(false)
    }
    return (
        <div className="track-job-steps-content-wrapper">

            <Accordion defaultActiveKey={currentStep}>
                <Accordion.Item eventKey="0" className={`${isActive(0) ? 'active' : ''} ${isCompleted(0) ? 'complete' : ''}`}>
                    <Accordion.Header>
                        <div className="step-wrapper">
                            <div className="count-wrapper">
                                {isCompleted(0) ?
                                    <FaCheck /> :
                                    <span>1</span>
                                }
                            </div>
                            <div className="contetn-wrapper">
                                <strong className="medium">Tracking URL Linked</strong>
                                <p>Provide the tracking URL for the content you are creating for the brand. All created content must be trackable for payment and accreditation.</p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="attatch-link-wrapper">
                            <button className="custon-btn light" onClick={editModalOpenHandler}><MdOutlineAttachment /> Link Tracking URL</button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={`${isActive(1) ? 'active' : ''} ${isCompleted(1) ? 'complete' : ''}`}>
                    <Accordion.Header>
                        <div className="step-wrapper">
                            <div className="count-wrapper">
                                {isCompleted(1) ?
                                    <FaCheck /> :
                                    <span>2</span>
                                }
                            </div>
                            <div className="contetn-wrapper">
                                <strong className="medium">Preliminary Discussions</strong>
                                <p>Discuss the project’s expectations, payment, and deadlines with the brand.</p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="attatch-link-wrapper">
                            <button className="custon-btn light"><FaEnvelope /> Message JS Health Vitamins</button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={`${isActive(2) ? 'active' : ''} ${isCompleted(2) ? 'complete' : ''}`}>
                    <Accordion.Header>
                        <div className="step-wrapper">
                            <div className="count-wrapper">
                                {isCompleted(2) ?
                                    <FaCheck /> :
                                    <span>3</span>
                                }
                            </div>
                            <div className="contetn-wrapper">
                                <strong className="medium">Payment Received</strong>
                                <p>Confirm the payment has been transferred to you by the brand.</p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="attatch-link-wrapper">
                            <button className="custon-btn light"><img src={confirmPayment} alt="confirmPayment" /> Confirm Payment</button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className={`${isActive(3) ? 'active' : ''} ${isCompleted(3) ? 'complete' : ''}`}>
                    <Accordion.Header>
                        <div className="step-wrapper">
                            <div className="count-wrapper">
                                {isCompleted(3) ?
                                    <FaCheck /> :
                                    <span>4</span>
                                }
                            </div>
                            <div className="contetn-wrapper">
                                <strong className="medium">Samples Received</strong>
                                <p>Confirm the brand’s products or samples have been received.</p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="attatch-link-wrapper">
                            <button className="custon-btn light"><IoIosCheckmarkCircle /> Confirm Receipt of Samples</button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className={`${isActive(4) ? 'active' : ''} ${isCompleted(4) ? 'complete' : ''}`}>
                    <Accordion.Header>
                        <div className="step-wrapper">
                            <div className="count-wrapper">
                                {isCompleted(4) ?
                                    <FaCheck /> :
                                    <span>5</span>
                                }
                            </div>
                            <div className="contetn-wrapper">
                                <strong className="medium">Content Creation In Progress</strong>
                                <p>Work on the video or live sessions as agreed.</p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="attatch-link-wrapper">
                            <div className="states-main-wrapper">
                                <div className="stats">
                                    <div className="text blue">
                                        <CircularProgressbar value={20} strokeWidth={16} />
                                        <span className="white">  2/5</span>
                                    </div>
                                    <div className="bottom">
                                        <img src={videofile} alt="videofile" />
                                        <span className="ex_small">Videos Created</span>
                                    </div>
                                </div>
                                <div className="stats">
                                    <div className="text pink">
                                        <CircularProgressbar value={20} strokeWidth={16} />
                                        <span className="white"> 2/5</span>
                                    </div>
                                    <div className="bottom">
                                        <img src={liveIcon} alt="liveIcon" />
                                        <span className="ex_small">Livestreams</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className={`${isActive(5) ? 'active' : ''} ${isCompleted(5) ? 'complete' : ''}`}>
                    <Accordion.Header>
                        <div className="step-wrapper">
                            <div className="count-wrapper">
                                {isCompleted(5) ?
                                    <FaCheck /> :
                                    <span>6</span>
                                }
                            </div>
                            <div className="contetn-wrapper">
                                <strong className="medium">Brand Review & Completion</strong>
                                <p>Once all content is submitted, the brand will review and confirm the project’s completion.</p>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="progress-wrapper-main mt-5">
                            <div className="progress-wrapper">
                                <div className="circular-progress-wrapper">
                                    <CircularProgressbar className="video-created-progress-bar blue" value={20} strokeWidth={13} />
                                    <strong className="total medium">5/5</strong>
                                </div>
                                <div className="text-wrapper">
                                    <h3>Videos Created</h3>
                                    <ul className="list-wrapper">
                                        <li><span className="label gray"></span> Expected Videos</li>
                                        <li><span className="label blue"></span> Videos Created</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="progress-wrapper">
                                <div className="circular-progress-wrapper">
                                    <CircularProgressbar className="video-created-progress-bar pink" value={20} strokeWidth={13} />
                                    <strong className="total medium">5/5</strong>
                                </div>
                                <div className="text-wrapper">
                                    <h3>Videos Created</h3>
                                    <ul className="list-wrapper">
                                        <li><span className="label gray"></span> Expected Videos</li>
                                        <li><span className="label pink"></span> Videos Created</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="progress-wrapper">
                                <div className="circular-progress-wrapper">
                                    <CircularProgressbar className="video-created-progress-bar gradient" value={20} strokeWidth={13} />
                                    <strong className="total medium">$87</strong>
                                </div>
                                <div className="text-wrapper">
                                    <h3>Videos Created</h3>
                                    <ul className="list-wrapper">
                                        <li><span className="label gray"></span> Expected Videos</li>
                                        <li><span className="label gradient"></span> Videos Created</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="notification-wrapper">
                            <HiMiniInformationCircle />
                            <strong>Burger Kind was impressed with your performance and would like to keep working with you</strong>
                        </div>
                        <div className="attatch-link-wrapper">
                            <button className="custon-btn light"><TbRefresh /> Accept renewal</button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>


            <LinkTrackingUrlModal editModalState={editModalState} editModalCloseHandler={editModalCloseHandler} />
        </div>
    )
}