import React, { useState } from "react";
import shopIcon from '../assets/images/icons/shop-icon.svg'
import userCreatorBoxIcon from '../assets/images/icons/user-creator-box-icon.svg'
import LogoTopBar from "../components/LogoTopBar";
import { Link } from "react-router-dom";

export default function ChoosePlatform() {
    const [activeSignUpTab, setActiveSignUpTab] = useState("");
    const activeTabHandler = (view) => {
        setActiveSignUpTab(view);
    };
    return (
        <div className="choose-platform-page">
            <LogoTopBar />
            <div className="innner-page-wrppaer">
                <div className="page-inner-content-wrapper">
                    <div className="text">
                        <h2>Join the Platform that Connects Brands & Creators</h2>
                        <p className="medium dark">Choose an account type to begin</p>
                    </div>
                    <div className="choose-platform-cards-wrapper">
                        <div className="container choose-platform-container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className={`choose-platform-card brand-card ${activeSignUpTab === 'brand' ? 'brand-selected' : ''}`} onClick={() => activeTabHandler("brand")}>
                                        <div className="radio-btn">
                                            <input type="radio" checked={activeSignUpTab === 'brand'} className="radio-input" />
                                            <span className="checkmark"></span>
                                        </div>
                                        <div className="icon-wrapper">
                                            <img src={shopIcon} alt="shopIcon" />
                                        </div>
                                        <div className="text-wrapper">
                                            <strong className="black medium">I’m a Brand</strong>
                                            <p>Looking to collaborate with talented creators to elevate your campaigns? Find influencers, manage partnerships, and expand your reach.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className={`choose-platform-card creator-card ${activeSignUpTab === 'creator' ? 'creator-selected' : ''}`} onClick={() => activeTabHandler("creator")}>
                                        <div className="radio-btn"> 
                                            <input type="radio" checked={activeSignUpTab === 'creator'} className="radio-input" />
                                            <span className="checkmark"></span>
                                        </div>
                                        <div className="icon-wrapper">
                                            <img src={userCreatorBoxIcon} alt="userCreatorBoxIcon" />
                                        </div>
                                        <div className="text-wrapper">
                                            <strong className="black medium">I’m a Creator</strong>
                                            <p>Ready to partner with top brands? Showcase your skills, land opportunities, and grow your influence while working on exciting projects.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrapper text-center">
                        <Link to="/sign-up" className={`btn-style large ${activeSignUpTab === 'creator' ? 'blue-btn' : activeSignUpTab === 'brand' ? 'pink-btn' : 'disabled' }`}>Create Account</Link>
                        <p className="medium dark medium-bold">Already have an account? <Link to="/sign-in" className="pink">Log In</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}