import React from "react";
import { IoMdAttach } from "react-icons/io";


export default function NoLinksComponent () {
    return(
        <div className="no-links-main-wrapper">
            <IoMdAttach/>
            <strong>No links attached</strong>
        </div>
    )
}