export const returnArray = (arr = []) => {
  if (arr && Array.isArray(arr) && arr.length > 0)
    return arr;
  else return [];
}

export const RenderMap = ({ arr = [], component, notFound }) => {
  if (arr && Array.isArray(arr) && arr.length > 0)
    return component;
  else return notFound;
}

export const setValueIfExist = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      (!Array.isArray(value) || value.length > 0)
    ) {
      acc[key] = value;
    }

    return acc;
  }, {});
};
